import PropTypes from "prop-types"
import React from "react"

import Alert from "utils/Alert"
import Athlete from "athletes/athlete"
import College from "colleges/college"
import CollegeRecommendationCard from "colleges/CollegeRecommendationCard"

const CollegeRecommendationList = ({
  athlete,
  colleges,
  onAthleteUpdate,
  recommendedCollegeIds,
}) => {
  if (colleges.length === 0) {
    return (
      <Alert type="warning">There are no colleges matching your search.</Alert>
    )
  }

  const handleRecommendation = (college, isRecommended) => {
    const targetCollege = athlete.collegeActivity.find(
      (currentAthleteCollege) => {
        return currentAthleteCollege.collegeId === college.id
      }
    )

    if (targetCollege !== undefined) {
      targetCollege.coachRecommended = isRecommended
      onAthleteUpdate(athlete)
    } else {
      const newCollege = {
        collegeId: college.id,
        collegeName: college.name,
        collegeCity: college.city,
        collegeState: college.state,
        divisionName: college.division,
        hasCollegeActivity: false,
        logoUrl: college.logoUrl,
        athleteReceivedEmailsCount: 0,
        athleteSentEmailsCount: 0,
        coachFollowsCount: 0,
        profileViewsCount: 0,

        athleteInterested: false,
        coachRecommended: isRecommended,
      }
      athlete.collegeActivity = [...athlete.collegeActivity, newCollege]
      onAthleteUpdate(athlete)
    }
  }

  colleges.forEach(
    (college) =>
      (college.isRecommended = recommendedCollegeIds.includes(college.id))
  )

  return (
    <div>
      <ul className="cards">
        {colleges.map((college) => (
          <CollegeRecommendationCard
            athleteId={athlete.id}
            college={college}
            key={college.id}
            onRecommendation={handleRecommendation}
          />
        ))}
      </ul>
    </div>
  )
}

CollegeRecommendationList.propTypes = {
  athlete: Athlete.schema.isRequired,
  colleges: PropTypes.arrayOf(College.schema).isRequired,
  onAthleteUpdate: PropTypes.func.isRequired,
  recommendedCollegeIds: PropTypes.array.isRequired,
}

export default CollegeRecommendationList
