import PropTypes from "prop-types"
import React from "react"

import { VisibilityLdr } from "../loader"

const LdrCardPhoto = VisibilityLdr(() =>
  import(/* webpackChunkName: "CardPhoto" */ "./CardPhotoImage")
)

export default class CardPhoto extends React.Component {
  constructor(props) {
    super(props)

    this.handleError = this.handleError.bind(this)

    this.state = {
      failed: false,
    }
  }

  handleError() {
    this.setState({ failed: true })
  }

  render() {
    return (
      <div className="profile-image image-xs">
        <LdrCardPhoto
          alt={this.props.altImageText}
          onError={this.handleError}
          src={
            this.state.failed ? this.props.defaultImage : this.props.photoUrl
          }
        />
      </div>
    )
  }
}

CardPhoto.propTypes = {
  photoUrl: PropTypes.string.isRequired,
  altImageText: PropTypes.string.isRequired,
  defaultImage: PropTypes.string,
}

CardPhoto.defaultProps = {
  defaultImage:
    "https://d100ib955j0sv7.cloudfront.net/fasttrackfiles/default_client_image.png",
}
