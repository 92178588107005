const join = (params) => {
  const filteredParams = params.filter((_) => _ !== "")

  if (filteredParams.length > 0) {
    return "?" + filteredParams.join("&")
  } else {
    return ""
  }
}

const filter = (key, value) => param("filter", key, value)

const page = (key, value) => param("page", key, value)

const param = (type, key, value) => {
  return value ? `${type}[${key}]=${value}` : ""
}

export { filter, join, page }
