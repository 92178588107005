import { ORGANIZATIONS_PAGE_CHANGE } from "store/actions/organizations"

const initialPage = 1

export default (state = initialPage, action) => {
  const { type } = action
  switch (type) {
    case ORGANIZATIONS_PAGE_CHANGE: {
      const { page } = action
      return page
    }
    default:
      return state
  }
}
