export const ENABLE_BUTTON = "ENABLE_BUTTON"
export const DISABLE_BUTTON = "DISABLE_BUTTON"

export const enableButton = () => {
  return {
    type: ENABLE_BUTTON,
  }
}

export const disableButton = () => {
  return {
    type: DISABLE_BUTTON,
  }
}
