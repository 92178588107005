import PropTypes from "prop-types"
import React from "react"

const RESOURCE_TYPE = "evaluation-guidelines"

const assemble = (data) => {
  const guidelinesSummary = data.attributes["guidelines-summary"]
  return {
    1: guidelinesSummary["1"],
    2: guidelinesSummary["2"],
    3: guidelinesSummary["3"],
    4: guidelinesSummary["4"],
    5: guidelinesSummary["5"],
    6: guidelinesSummary["6"],
    detailedGuidelinesUrl: data.attributes["details-url"],
  }
}

const schema = PropTypes.shape({
  1: PropTypes.array.isRequired,
  2: PropTypes.array.isRequired,
  3: PropTypes.array.isRequired,
  4: PropTypes.array.isRequired,
  5: PropTypes.array.isRequired,
  6: PropTypes.array.isRequired,
  detailedGuidelinesUrl: PropTypes.string.isRequired,
})

export default {
  RESOURCE_TYPE,
  assemble,
  schema,
}
