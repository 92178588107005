import PropTypes from "prop-types"
import React from "react"
import { assembleResource } from "utils/providers/assemble-resource"
import GetData from "utils/GetData"

const ResourceProvider = ({
  componentProps,
  component,
  endpoint,
  resourceName,
  includeResponsePayload,
}) => {
  const renderComponent = (responsePayload) => {
    const Component = component
    const newProps = { [resourceName]: assembleResource(responsePayload.data) }

    if (includeResponsePayload) {
      newProps["responsePayload"] = responsePayload
    }

    return <Component {...componentProps} {...newProps} />
  }

  return <GetData endpoint={endpoint} renderComponent={renderComponent} />
}

export default ResourceProvider

ResourceProvider.propTypes = {
  component: PropTypes.func.isRequired,
  endpoint: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  componentProps: PropTypes.object,
  includeResponsePayload: PropTypes.bool,
}
