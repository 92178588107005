import PropTypes from "prop-types"
import React from "react"

const RESOURCE_TYPE = "sports"

const assemble = (data) => {
  return {
    id: data.id,
    name: data.attributes["name"],
    displayName: data.attributes["display-name"],
  }
}

const schema = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
})

export default {
  RESOURCE_TYPE,
  assemble,
  schema,
}
