import * as types from "store/actions/organizations"

export default (
  state = {
    isFetching: false,
    isError: false,
  },
  action
) => {
  switch (action.type) {
    case types.UPDATE_ADMIN_FILTERS:
      return {
        ...state,
        isError: false,
      }
    case types.REQUEST_ORGANIZATIONS:
      return {
        ...state,
        isFetching: true,
      }
    case types.FAILURE_ORGANIZATIONS:
      return {
        ...state,
        isFetching: false,
        isError: true,
      }
    case types.RECEIVE_ORGANIZATIONS:
      return {
        ...state,
        isFetching: false,
      }
    default:
      return state
  }
}
