import _ from "lodash"
import React from "react"

import { collegeIndexEndpoint, collegeFilterEndpoint } from "utils/endpoints"
import CollegeSearchResults from "colleges/CollegeSearchResults"
import SearchBar from "utils/search/SearchBar"
import E from "utils/tracking/event-parameters"
import Track from "utils/tracking/track"
import ContentContainer from "utils/ContentContainer"
import CollegeFilterContainer from "colleges/CollegeFilterContainer"
import CollegeMobileControlPanel from "colleges/mobile-control/CollegeMobileControlPanel"

export default class CollegeSearch extends React.Component {
  constructor(props) {
    super(props)

    this.onSearch = this.onSearch.bind(this)
    this.onFilter = this.onFilter.bind(this)
    this.onOpenFilters = this.onOpenFilters.bind(this)
    this.catchFilterChanges = this.catchFilterChanges.bind(this)
    this.setDefaultState = this.setDefaultState.bind(this)
    this.defaultState = {
      endpoint: collegeIndexEndpoint(),
      pageNumber: 1,
      searchQuery: "",
      filters: {},
      showOpenFilters: false,
      hasFilteringApplied: false,
    }

    this.state = {
      ...this.defaultState,
    }
  }

  componentWillMount() {
    const searchQuery = _.get(this.props.location, ["query", "searchQuery"], "")
    const stateCode = _.get(this.props.location, ["query", "stateCode[]"], [])
    const filters = {
      keywords: searchQuery,
      stateCode: _.isArray(stateCode) ? stateCode : [stateCode],
    }
    this.setState({
      filters,
      searchQuery: filters.keywords,
      endpoint: collegeFilterEndpoint({ filters, pageNumber: 1 }),
    })
  }

  componentDidMount() {
    this.onFilter(this.state.filters)
  }

  setDefaultState() {
    this.setState({
      ...this.defaultState,
    })
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.searchQuery !== this.state.searchQuery) {
      this.setState({
        pageNumber: 1,
      })
    }
  }

  catchFilterChanges(value) {
    this.setState({ hasFilteringApplied: value })
  }

  onSearch(query) {
    this.setState({ searchQuery: query }, () => {
      this.onFilter(this.state.filters)
    })
  }

  onFilter(filters = {}) {
    filters.keywords = this.state.searchQuery
    const pageNumber =
      filters === this.state.filters ? this.state.pageNumber : 1

    this.setState({
      endpoint: collegeFilterEndpoint({ filters, pageNumber }),
      filters: filters,
    })

    Track.event({
      action: E.ACTION.SEARCH_COLLEGE,
      category: E.CATEGORY.COLLEGE_SHOW_PAGE,
    })
  }

  onOpenFilters() {
    this.setState({ showOpenFilters: true })
  }

  resolveFilterTags(value) {
    const filterLabel = value[0]
    const filterValue = value[1]

    switch (filterLabel) {
      case "tuitionRange": {
        return (
          <div className="m-control-panel-tag" key={`${filterLabel}`}>
            {tuitionRange[filterValue[0]]} - {tuitionRange[filterValue[1]]}
          </div>
        )
      }
      case "enrollmentRange": {
        return (
          <div className="m-control-panel-tag" key={`${filterLabel}`}>
            {enrollmentRange[filterValue[0]]} -{" "}
            {enrollmentRange[filterValue[1]]}
          </div>
        )
      }
      case "publicPrivate":
        return filterValue.map((t, i) => (
          <div className="m-control-panel-tag" key={`${filterLabel}-${i}`}>
            {publicPrivate[t]}
          </div>
        ))
      case "collegeType":
        return filterValue.map((t, i) => (
          <div className="m-control-panel-tag" key={`${filterLabel}-${i}`}>
            {collegeType[t]}
          </div>
        ))
      case "academicStrength":
        return filterValue.map((t, i) => (
          <div className="m-control-panel-tag" key={`${filterLabel}-${i}`}>
            {academicStrength[t]}
          </div>
        ))
      default:
        return filterValue.map((t, i) => (
          <div className="m-control-panel-tag" key={`${filterLabel}-${i}`}>
            {t}
          </div>
        ))
    }
  }

  getFilterTags() {
    const { keywords, ...rest } = this.state.filters
    const appliedValues = Object.entries(rest).reduce((acc, cur) => {
      if (cur[1].length) {
        return [...acc, cur]
      }

      return [...acc]
    }, [])

    return appliedValues.length
      ? appliedValues.map((value) => {
          return this.resolveFilterTags(value)
        })
      : ""
  }

  render() {
    return (
      <ContentContainer
        className="section-content"
        direction="column"
        block="block"
      >
        <h1>Find Colleges</h1>
        <SearchBar
          label=""
          onSearch={this.onSearch}
          placeholder="Search by name, location, or division..."
          defaultValue={this.state.searchQuery}
        />
        <div className={"hidden-xs"}>
          <CollegeFilterContainer
            showFilters={this.state.showOpenFilters}
            catchFilterChanges={this.catchFilterChanges}
            onClearFromParent={this.setDefaultState}
            onFilter={this.onFilter}
            filters={this.state.filters}
          />
        </div>
        <CollegeSearchResults
          filtersApplied={this.state.hasFilteringApplied}
          endpoint={this.state.endpoint}
          filters={this.state.filters}
          searchQuery={this.state.searchQuery}
          onOpenFilters={this.onOpenFilters}
        />

        <CollegeMobileControlPanel
          appliedFilters={this.getFilterTags()}
          onFilter={this.onFilter}
          catchFilterChanges={this.catchFilterChanges}
          onClearFromParent={this.setDefaultState}
        />
      </ContentContainer>
    )
  }
}

const enrollmentRange = {
  0: "<1K",
  5000: "5K",
  10000: "10K",
  20000: "20K",
  30000: "30K",
  500000: "40K+",
}

const publicPrivate = {
  PUBLIC: "Public",
  PRIVATE: "Private",
}

const collegeType = {
  MILITARY: "Military",
  HBC: "HBC",
  CHRISTIAN: "Christian",
  WOMEN: "Women's",
  MEN: "Men's",
}

const academicStrength = {
  1: "High",
  2: "Medium",
  3: "Low",
}

const tuitionRange = {
  0: "$0",
  5000: "$5K",
  10000: "$10K",
  20000: "$20K",
  30000: "$30K",
  100000: "$40K+",
}
