import PropTypes from "prop-types"
import React from "react"

import Icon from "utils/Icon"
const ActivityTable = ({ metrics, hideData }) => {
  const renderDataFilterMsg = () => {
    return (
      <td className="premium-access" colSpan={metrics.length}>
        <span className="fa fa-lock" />
        Premium Access Only
      </td>
    )
  }

  const renderMetrics = () => {
    return metrics.map((metric, index) => (
      <td key={index} className={`small ${metric.styleClass || ""}`}>
        {metric.value || "-"}
      </td>
    ))
  }

  return (
    <table className="card-table">
      <tbody>
        <tr className="metrics">
          {hideData ? renderDataFilterMsg() : renderMetrics()}
        </tr>

        <tr>
          {metrics.map((metric, index) => (
            <th key={index}>
              {metric.labelIcon && (
                <Icon type={metric.labelIcon} set={metric.labelIconSet} />
              )}

              {metric.labelIcon && " "}

              {metric.label}
            </th>
          ))}
        </tr>
      </tbody>
    </table>
  )
}

ActivityTable.propTypes = {
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      labelIcon: PropTypes.string,
      styleClass: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  hideData: PropTypes.bool,
}

export default ActivityTable
