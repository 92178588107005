const sortBy = (data, getValue, comparator = ascendingComparator) => {
  return [...data].sort((a, b) => comparator(getValue(a), getValue(b)))
}

const generateComparator = (val) => {
  return (a, b) => {
    if (a === null) {
      return -val
    }
    if (b === null) {
      return val
    }
    if (a < b) {
      return -val
    }
    if (a > b) {
      return val
    }
    return 0
  }
}

const ascendingComparator = generateComparator(1)
const descendingComparator = generateComparator(-1)

export default {
  sortBy,
  ascendingComparator,
  descendingComparator,
}
