import PropTypes from "prop-types"
import React from "react"

const CardText = ({ children, title, subtitle }) => {
  return (
    <div className="card-details">
      <h4>{title}</h4>

      <div className="subtitle">{subtitle}</div>
      {children}
    </div>
  )
}

CardText.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
}

export default CardText
