import PropTypes from "prop-types"
import React from "react"

const RESOURCE_TYPE = "teams"

const assemble = (data) => {
  return {
    id: data.id,
    name: data.attributes["name"],
    sport: data.attributes["sport"],
    sportId: String(data.attributes["sport-id"]),
    sportSlug: data.attributes["sport-slug"],
    orgState: data.attributes["org-state"],
    orgSlug: data.attributes["org-slug"],
    scheduleUrl: data.attributes["schedule-url"],
    unassigned: data.attributes["unassigned"],
  }
}

const schema = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sport: PropTypes.string.isRequired,
  sportId: PropTypes.string.isRequired,
  sportSlug: PropTypes.string,
  orgState: PropTypes.string,
  orgSlug: PropTypes.string,
  scheduleUrl: PropTypes.string,
  unassigned: PropTypes.bool,
})

export default {
  RESOURCE_TYPE,
  assemble,
  schema,
}
