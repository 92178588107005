import PropTypes from "prop-types"
import React from "react"

import classnames from "classnames"
import Icon from "utils/Icon"

const MobileControlScreen = ({ children, title, isOpen, onToggleScreen }) => {
  const screenClasses = classnames("m-control-screen", { "is-open": isOpen })

  return (
    <div className={screenClasses}>
      <div className="m-control-screen-header">
        {title}
        <Icon onClick={onToggleScreen} type="times-circle" set="far" />
      </div>

      <div className="m-control-screen-body">{children}</div>
    </div>
  )
}

MobileControlScreen.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onToggleScreen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

export default MobileControlScreen
