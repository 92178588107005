import _ from "lodash"
import {
  SET_APP_SPORT,
  TOGGLE_COLLEGE_RECOMMENDATION,
  FETCH_UPDATES_FEED,
} from "store/actions/dashboard"
import { setSelectedSport } from "utils/sportSwitcher"

export const INITIAL_STATE = {
  activeSportId: null,
  updatesFeed: {
    loading: false,
    loaded: false,
    data: [],
    error: null,
  },
}

function setAppSport(state, action) {
  setSelectedSport(action.payload)
  return { ...state, activeSportId: action.payload }
}

function fetchUpdatesFeedLoading(state) {
  return {
    ...state,
    updatesFeed: {
      ...state.updatesFeed,
      loading: true,
    },
  }
}

function fetchUpdatesFeedSuccess(state, action) {
  return {
    ...state,
    updatesFeed: {
      ...state.updatesFeed,
      loading: false,
      loaded: true,
      data: action.payload,
    },
  }
}

function fetchUpdatesFeedFailure(state, action) {
  return {
    ...state,
    updatesFeed: {
      ...state.updatesFeed,
      loading: false,
      loaded: true,
      error: action.payload,
    },
  }
}

function toggleCollegeRecommendation(state, action) {
  const { updatesFeed } = state
  const newEventData = updatesFeed.data.map((event) => {
    // Any event with the same athleteId, collegeId, and followUp should
    // update when a athlete college recommendation is changed.
    // NOTE: An alternative to making this update would be calling fetchUpdates.
    if (
      event.athlete_id === action.payload.athlete_id &&
      event.college_id === action.payload.college_id &&
      _.includes(
        ["recommend_college", "remove_recommendation"],
        event.follow_up
      )
    ) {
      return {
        ...event,
        follow_up:
          event.follow_up === "recommend_college"
            ? "remove_recommendation"
            : "recommend_college",
      }
    }
    return event
  })
  return {
    ...state,
    updatesFeed: {
      ...updatesFeed,
      data: newEventData,
    },
  }
}

const dashboard = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_APP_SPORT:
      return setAppSport(state, action)
    case FETCH_UPDATES_FEED.LOADING:
      return fetchUpdatesFeedLoading(state, action)
    case FETCH_UPDATES_FEED.SUCCESS:
      return fetchUpdatesFeedSuccess(state, action)
    case FETCH_UPDATES_FEED.FAILURE:
      return fetchUpdatesFeedFailure(state, action)
    case TOGGLE_COLLEGE_RECOMMENDATION:
      return toggleCollegeRecommendation(state, action)
    default:
      return state
  }
}

export default dashboard
