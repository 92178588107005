import PropTypes from "prop-types"
import React from "react"

const FieldErrors = ({ errors }) => {
  return (
    <div className="status">
      <ul className="list-unstyled">
        {errors.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </div>
  )
}

FieldErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default FieldErrors
