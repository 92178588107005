import { combineReducers } from "redux"

import meta from "store/reducers/organizations/meta"
import byId from "store/reducers/organizations/byId"
import byFilter from "store/reducers/organizations/byFilter"
import filters from "store/reducers/organizations/filters"
import currentPage from "store/reducers/organizations/currentPage"

const currentOrganizations = combineReducers({
  meta: meta,
  filters: filters,
  byId: byId,
  byFilter: byFilter,
  currentPage: currentPage,
})

export default currentOrganizations

const getIds = (state) => {
  const { filters, byFilter, currentPage } = state

  const key = getFilterKey(filters)

  return byFilter[key] && byFilter[key]["pages"][currentPage]
    ? byFilter[key]["pages"][currentPage]
    : []
}

export const getTotalNumberOfPages = (state) => {
  const { filters, byFilter } = state

  const key = getFilterKey(filters)

  return byFilter[key] ? byFilter[key]["totalPages"] : 0
}

export const getFilterKey = (filters) => {
  const { chosenStatus, textQuery, orgType } = filters
  return `${chosenStatus || "-"} | ${textQuery || "-"} | ${orgType || "-"}`
}

export const getOrganizations = (state) => {
  const { byId } = state

  return getIds(state)
    .map((id) => byId[id])
    .filter(Boolean)
}
