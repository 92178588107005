import "autotrack/lib/plugins/outbound-link-tracker"
import "autotrack/lib/plugins/page-visibility-tracker"
import "autotrack/lib/plugins/url-change-tracker"
import { Tabs } from "react-tabs"
import React from "react"
import { render } from "react-dom"
import { Provider } from "react-redux"
import configureStore from "store/configureStore"
import { setupDataDogRUM } from "./errorhandling/setupDataDogRUM"
import Request from "utils/Request"
import { environmentConfig, environmentConfigUrl } from "./utils/env-config"

import AppRouter from "app/AppRouter"
import Track from "utils/tracking/track"

import "@babel/polyfill"

require("./styles/vendor")
require("./styles/global")

Tabs.setUseDefaultStyles(false)

const rootElement = document.getElementById("app")

const onConfigurationLoaded = (config) => {
  environmentConfig.env = config.json

  Track.initializeTracking()
  setupDataDogRUM()
  const store = configureStore()

  render(
    <Provider store={store}>
      <AppRouter />
    </Provider>,
    rootElement
  )
}

const onConfigurationError = (error) => {
  console.error("error loading env.json", error)
  render(<p>Error loading application configuration</p>, rootElement)
}

Request.get({
  endpoint: environmentConfigUrl,
  onSuccess: onConfigurationLoaded,
  onFailure: onConfigurationError,
})
