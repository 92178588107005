import * as types from "store/actions/organizations"

const initialState = {
  chosenStatus: "",
  textQuery: "",
  orgType: "",
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_ADMIN_FILTERS:
      return {
        ...state,
        ...action.filters,
      }
    default:
      return state
  }
}
