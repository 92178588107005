import _ from "lodash"
import React from "react"
import PropTypes from "prop-types"
import BEM from "utils/bem"

const BLOCK = new BEM("flex-box")

/**
 * FlexBox is a reusable, multipurpose UI component. It can be used to create
 * a layout, or a grid. Eventually it should support padding/gutters and more.
 * FlexBox is modeled off of Grommet's Box component: https://v2.grommet.io/box
 */
function FlexBox(props) {
  const TagComponent = props.tag
  const flexStyle = _.isNumber(props.flex)
    ? `${props.flex} 1 auto`
    : `${props.flex.grow} ${props.flex.shrink} ${props.flex.basis || "auto"}`
  return (
    <TagComponent
      className={BLOCK.m(`direction-${props.direction}`)
        .m(`align-${props.align}`)
        .m(`justify-${props.justify}`)
        .m(`wrap-${props.wrap}`)
        .m(props.block && `block-${props.block}`)
        .addClass(props.className)}
      style={{ flex: flexStyle }}
      onClick={props.onClick} // TODO: Render a `button` if an `onClick` is provided.
    >
      {props.children}
    </TagComponent>
  )
}

FlexBox.propTypes = {
  tag: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  direction: PropTypes.oneOf(["column", "row"]),
  align: PropTypes.oneOf(["start", "center", "end", "baseline", "stretch"]),
  wrap: PropTypes.oneOf(["nowrap", "wrap", "wrap-reverse"]),
  justify: PropTypes.oneOf([
    "around",
    "between",
    "center",
    "end",
    "evenly",
    "start",
    "stretch",
  ]),
  flex: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      grow: PropTypes.number.isRequired,
      shrink: PropTypes.number.isRequired,
      basis: PropTypes.string,
    }),
  ]),
  block: PropTypes.oneOf(["block", "inline"]),
}

FlexBox.defaultProps = {
  tag: "div",
  onClick() {},
  className: "",
  direction: "row",
  wrap: "nowrap",
  align: "start",
  justify: "start",
  flex: 1,
  block: null,
}

export default FlexBox
