import PropTypes from "prop-types"
import React from "react"
import { TreeNode, TreeType } from "utils/forms/tree_selector/TreeNode"

class TreeSelector extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleToggleVisibility = this.handleToggleVisibility.bind(this)
    this.renderDisclosureIcon = this.renderDisclosureIcon.bind(this)

    this.state = {
      isVisible: props.isInitiallyVisible,
    }
  }

  handleChange(nodes, isSelected) {
    const { name, selectedNodes, onChange } = this.props

    const selectedNodeSet = new Set(selectedNodes)
    if (isSelected) {
      nodes.forEach((node) => {
        selectedNodeSet.add(node)
      })
    } else {
      nodes.forEach((node) => {
        selectedNodeSet.delete(node)
      })
    }

    // Needed because spread operator or Array.from not working for some reason:
    const selectedNodeArray = []
    selectedNodeSet.forEach((v) => selectedNodeArray.push(v))

    onChange(name, selectedNodeArray)
  }

  handleToggleVisibility() {
    if (this.props.isToggleable) {
      this.setState((prevState) => ({
        isVisible: !prevState.isVisible,
      }))
    }
  }

  renderDisclosureIcon() {
    const [action, icon] = this.state.isVisible ? ["Hide", "▼"] : ["Show", "▶"]
    const title = `Click Arrow to ${action} ${this.props.label.replace(
      ":",
      ""
    )} Selector`
    return (
      this.props.isToggleable && (
        <span className="disclosure-icon" title={title}>
          {icon}&nbsp;
        </span>
      )
    )
  }

  render() {
    const { name, label, dataTree, selectedNodes } = this.props

    return (
      <div className="tree-selector" id={`tree-selector-${name}`}>
        <h4 onClick={this.handleToggleVisibility}>
          {this.renderDisclosureIcon()}&nbsp;
          <span className="tree-selector-label">{label}</span>
        </h4>

        <div
          className="tree-selector-box"
          style={{ display: this.state.isVisible ? "block" : "none" }}
        >
          {dataTree.children.map((subTree) => (
            <TreeNode
              key={subTree.id}
              dataTree={subTree}
              selectedNodes={selectedNodes}
              onChange={this.handleChange}
            />
          ))}
        </div>
      </div>
    )
  }
}

TreeSelector.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  dataTree: TreeType,
  onChange: PropTypes.func,
  selectedNodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  isInitiallyVisible: PropTypes.bool,
  isToggleable: PropTypes.bool,
}

TreeSelector.defaultProps = {
  isInitiallyVisible: true,
  isToggleable: false,
}

export default TreeSelector
