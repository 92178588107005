import PropTypes from "prop-types"
import React from "react"

const RESOURCE_TYPE = "organization-notes"

const assemble = (data) => {
  return {
    id: data.id,
    adminName: data.relationships["partner-admin"]["data"]["full-name"],
    date: data.attributes["date"],
    contactType: data.attributes["contact-type"],
    contactDetails: data.attributes["contact-details"],
    notes: data.attributes["notes"],
  }
}

const schema = PropTypes.shape({
  id: PropTypes.string.isRequired,
  adminName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  contactType: PropTypes.string.isRequired,
  contactDetails: PropTypes.string.isRequired,
  notes: PropTypes.string,
})

export default {
  RESOURCE_TYPE,
  assemble,
  schema,
}
