import Request from "utils/Request"
import { assembleResource } from "utils/providers/assemble-resource"
import { organizationContractIndexEndpoint } from "utils/endpoints"

export const REQUEST_CONTRACTS = "REQUEST_CONTRACTS"
export const RECEIVE_CONTRACTS = "RECEIVE_CONTRACTS"
export const ADD_CONTRACT = "ADD_CONTRACT"
export const DELETE_CONTRACT = "DELETE_CONTRACT"
export const UPDATE_CONTRACT = "UPDATE_CONTRACT"

const requestContracts = () => {
  return {
    type: REQUEST_CONTRACTS,
  }
}

const receiveContracts = (json) => {
  return {
    type: RECEIVE_CONTRACTS,
    contracts: assembleResource(json.data),
    receivedAt: Date.now(),
  }
}

const deleteContract = (contracts) => {
  return {
    type: DELETE_CONTRACT,
    contracts: contracts,
    updatedAt: Date.now(),
  }
}

const addContract = (contracts) => {
  return {
    type: ADD_CONTRACT,
    contracts: contracts,
    updatedAt: Date.now(),
  }
}

const fetchContracts = (organizationId) => {
  return (dispatch) => {
    dispatch(requestContracts())
    const onSuccess = (result) => {
      dispatch(receiveContracts(result.json))
    }

    return Request.get({
      endpoint: organizationContractIndexEndpoint(organizationId),
      onSuccess,
    })
  }
}

const shouldFetchContracts = (state) => {
  const contracts = state.contracts
  if (!contracts) {
    return true
  } else if (contracts.isFetching) {
    return false
  }
}

export const updateContract = (contract) => {
  return {
    type: UPDATE_CONTRACT,
    contract,
    updatedAt: Date.now(),
  }
}

export const insertContract = (json) => {
  const newContract = assembleResource(json.data)

  return (dispatch, getState) => {
    const contracts = getState().currentContracts.items
    contracts.push(newContract)

    return dispatch(addContract(contracts))
  }
}

export const handleContractUpdate = (updatedContract) => {
  return (dispatch) => {
    return dispatch(updateContract(updatedContract))
  }
}

export const removeContract = (id) => {
  return (dispatch, getState) => {
    const contracts = getState().currentContracts.items
    const newContracts = contracts.filter((contract) => {
      return contract.id !== id
    })

    return dispatch(deleteContract(newContracts))
  }
}

export const fetchContractsIfNeeded = (organizationId) => {
  return (dispatch, getState) => {
    if (shouldFetchContracts(getState())) {
      return dispatch(fetchContracts(organizationId))
    }
  }
}
