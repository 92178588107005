const diffObjects = (target, reference) => {
  const refDup = JSON.parse(JSON.stringify(reference))
  const diff = {}
  Object.keys(refDup).forEach((key) => {
    if (refDup[key] instanceof Array && target[key] instanceof Array) {
      if (arrayEquals(refDup[key], target[key])) {
        delete refDup[key]
        delete target[key]
      } else {
        diff[key] = target[key]
      }
    }
    if (!(refDup[key] === target[key])) {
      diff[key] = target[key]
    }
  })
  return diff
}

const arrayEquals = (a1, a2) => {
  return a1.length === a2.length && a1.every((v, i) => v === a2[i])
}

const exists = (value) => value !== null && value !== undefined && value !== ""

const orDefault = (value, defaultValue) =>
  exists(value) ? value : defaultValue

const randomId = (limit) => Math.floor(Math.random() * limit)

const transpose = (array) => array[0].map((_, i) => array.map((x) => x[i]))

const unique = (array) =>
  array.filter((value, index) => array.indexOf(value) === index)

const isFunction = (value) => typeof value === "function"

const debounce = (fn, ms = 0) => {
  let timeoutId
  return (...args) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn.apply(this, args), ms)
  }
}

const omit = (obj, arr) =>
  Object.keys(obj)
    .filter((k) => !arr.includes(k))
    .reduce((acc, key) => ((acc[key] = obj[key]), acc), {})

export {
  diffObjects,
  exists,
  isFunction,
  orDefault,
  randomId,
  transpose,
  unique,
  debounce,
  omit,
}
