import PropTypes from "prop-types"
import React from "react"
import OrgPartnerAdmin from "organizations/org-partner-admin"

const RESOURCE_TYPE = "organizations"

const assemble = (data) => {
  return {
    id: data.id,
    slug: data.attributes["slug"],
    address: data.attributes["address"],
    city: data.attributes["city"],
    email: data.attributes["email"],
    name: data.attributes["name"],
    phone: data.attributes["phone"],
    state: data.attributes["state"],
    website: data.attributes["website"],
    zipCode: data.attributes["zip-code"],
    logoUrl: data.attributes["logo-url"],
    sports: data.attributes["sports"] || [],
    sportNames: (data.attributes["sports"] || []).map(
      (sport) => sport.sport_name
    ),
    contractsStatus: data.attributes["contracts-status"],
    numberOfTeams: data.attributes["number-of-teams"],
    numberOfAthletes: data.attributes["number-of-athletes"],
    numberOfContracts: data.attributes["number-of-contracts"],
    primaryContactId: data.attributes["primary-contact"]["id"],
    primaryContactEmail: data.attributes["primary-contact"]["email"],
    primaryContactName: data.attributes["primary-contact"]["name"],
    primaryPartnerId: data.attributes["primary-partner-id"],
    affiliatedAdmins: (data.attributes["affiliated-partner-admins"] || []).map(
      OrgPartnerAdmin.assemble
    ),
    freeAgreementStatus: data.attributes["free-agreement-status"],
    signedContractTotal: data.attributes["signed-contract-total"],
    signedContractDates: data.attributes["signed-contract-dates"],
    lastSentContractAmount: data.attributes["last-sent-contract-amount"],
    lastSentContractDate: data.attributes["last-sent-contract-date"],
    verified: data.attributes["verified?"],
    createdAt: data.attributes["created-at"],
  }
}

const schema = PropTypes.shape({
  id: PropTypes.string.isRequired,
  address: PropTypes.string,
  city: PropTypes.string,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  state: PropTypes.string,
  website: PropTypes.string,
  zipCode: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
  sportNames: PropTypes.arrayOf(PropTypes.string),
  contractsStatus: PropTypes.string.isRequired,
  numberOfTeams: PropTypes.number,
  numberOfAthletes: PropTypes.number,
  numberOfContracts: PropTypes.number,
  primaryContactId: PropTypes.string,
  primaryContactEmail: PropTypes.string,
  primaryContactName: PropTypes.string,
  affiliatedAdmins: PropTypes.arrayOf(OrgPartnerAdmin.schema),
  freeAgreementStatus: PropTypes.string,
  verified: PropTypes.bool.isRequired,
})

export default {
  RESOURCE_TYPE,
  assemble,
  schema,
}
