import { isEmpty } from "lodash"
import Routes from "app/routes"
import Session from "app/session"
import Request from "utils/Request"
import { eventTrackingEndpoint } from "utils/endpoints"
import { determineUserAgent } from "utils/userAgent"

export const EVENT_TYPES = {
  PAGE_VIEW: 0,
  BUTTON_CLICK: 1,
}

export const BUTTON_CLICKS = {
  DASHBOARD_ADD_ATHLETES: "Dashboard - Add Athletes to Roster",
  EMAIL_ATHLETE: "Email Athlete",
  EMAIL_ALL_ATHLETES: "Email All Athletes",
  EMAIL_COACHES: "Email Coaches",
  REQUEST_TAP: "Request TAP",
  EMAIL_NCSA_ROSTER: "Email NCSA Roster",
  UPLOAD_ROSTER: "Upload Roster",
  GET_PUBLIC_LINK: "Get Public Link",
  INVITE_ALL_ATHLETES: "Invite All Athletes",
  INVITE_ATHLETE: "Invite Athlete",
}

export function trackEvent(eventType, details) {
  if (!Session.isCoach()) return Promise.resolve()
  return Request.post({
    endpoint: eventTrackingEndpoint(),
    payload: {
      data: {
        type: "coach_event_trackings",
        attributes: {
          event_type: eventType,
          coach_id: Session.userId(),
          agent_type: determineUserAgent(),
          occurred_at: new Date(),
          details,
        },
      },
    },
  })
}

export function trackPageView(path, details = {}) {
  return trackEvent(EVENT_TYPES.PAGE_VIEW, pageDetails(path, details))
}

export function trackButtonClick(name, path) {
  return trackEvent(EVENT_TYPES.BUTTON_CLICK, {
    button_name: name,
    button_path: path,
  })
}

function pageDetails(path, details = {}) {
  const defaultDetails = {
    path: path || "",
    tab: "",
    referrer: "",
  }
  /**
   * TODO: If we can move the tab infomation into react-router, we may be able to avoid this.
   * Here we're manually tracking the first tab that a user would land on if they visited
   * these particular routes. This is obviously brittle (what if we change the first tab? then
   * we have to remember to change this! and who knows if we will...)
   */
  if (Routes.isActive(path, Routes.activity()) && !details.tab) {
    return { ...defaultDetails, ...details, tab: "Athlete" }
  }
  if (Routes.isActive(path, Routes.athlete("")) && !details.tab) {
    return { ...defaultDetails, ...details, tab: "College Coach Activity" }
  }
  if (Routes.isActive(path, Routes.rosterManagment("")) && !details.tab) {
    return { ...defaultDetails, ...details, tab: "Athletes" }
  }
  return { ...defaultDetails, ...details }
}
