import React from "react"
import FlexBox from "utils/FlexBox"
import BEM from "utils/bem"

const BLOCK = new BEM("content-container")

function ContentContainer(props) {
  return (
    <FlexBox
      {...props}
      className={BLOCK.addClass(props.className).toString()}
    />
  )
}

ContentContainer.defaultProps = {
  className: "",
}

export default ContentContainer
