import { athleteCollegeRecommendationEndpoint } from "utils/endpoints"
import Request from "utils/Request"
import Session from "app/session"

const makeRequest = (
  athleteId,
  collegeId,
  isRecommending,
  { onSuccess, onFailure }
) => {
  const recommendAction = isRecommending ? "create" : "delete"

  const assemblePayload = () => ({
    data: {
      type: "recommendations",
      attributes: { college_id: collegeId, action: recommendAction },
      relationships: {
        athlete: { data: { type: "athletes", id: athleteId } },
        organization: {
          data: { type: "organizations", id: Session.organizationId() },
        },
      },
    },
  })

  const doNothing = () => {}

  return Request.post({
    endpoint: athleteCollegeRecommendationEndpoint(athleteId),
    payload: assemblePayload(),
    onSuccess: onSuccess || doNothing,
    onFailure: onFailure || doNothing,
  })
}

const RequestRecommendCollege = {
  execute: (athleteId, collegeId, handlers) =>
    makeRequest(athleteId, collegeId, true, handlers),
  undo: (athleteId, collegeId, handlers) =>
    makeRequest(athleteId, collegeId, false, handlers),
}

export default RequestRecommendCollege
