import Request from "utils/Request"

import { assembleResource } from "utils/providers/assemble-resource"
import { teamIndexEndpoint } from "utils/endpoints"

export const REQUEST_TEAMS = "REQUEST_TEAMS"
export const RECEIVE_TEAMS = "RECEIVE_TEAMS"

const requestTeams = () => {
  return {
    type: REQUEST_TEAMS,
  }
}

const receiveTeams = (json) => {
  return {
    type: RECEIVE_TEAMS,
    teams: assembleResource(json.data),
    receivedAt: Date.now(),
  }
}

const fetchTeams = () => {
  return (dispatch) => {
    dispatch(requestTeams())
    const onSuccess = (result) => {
      dispatch(receiveTeams(result.json))
    }

    return Request.get({ endpoint: teamIndexEndpoint(), onSuccess })
  }
}

const shouldFetchTeams = (state) => {
  const teams = state.teams
  if (!teams) {
    return true
  } else if (teams.isFetching) {
    return false
  }
}

export const fetchTeamsIfNeeded = () => {
  return (dispatch, getState) => {
    if (shouldFetchTeams(getState())) {
      return dispatch(fetchTeams())
    }
  }
}
