import PropTypes from "prop-types"
import React from "react"

const RESOURCE_TYPE = "pricing-tiers"

const assemble = (data) => {
  return {
    id: data.id,
    maxAthletes: data.attributes["max-athletes"],
    maxTeams: data.attributes["max-teams"],
    minTeams: data.attributes["min-teams"],
    price: data.attributes["price"],
  }
}

const schema = PropTypes.shape({
  id: PropTypes.string.isRequired,
  maxAthletes: PropTypes.number.isRequired,
  maxTeams: PropTypes.number.isRequired,
  minTeams: PropTypes.number.isRequired,
  price: PropTypes.string.isRequired,
})

export default {
  RESOURCE_TYPE,
  assemble,
  schema,
}
