import { createStore, applyMiddleware, compose } from "redux"
import thunkMiddleware from "redux-thunk"
import createRavenMiddleware from "raven-for-redux"

import teamEditionReducers from "store/reducers/reducers"
import setupRaven from "Error/setupRaven"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function configureStore(preloadedState) {
  const raven = setupRaven()
  return createStore(
    teamEditionReducers,
    preloadedState,
    composeEnhancers(
      applyMiddleware(thunkMiddleware, createRavenMiddleware(raven))
    )
  )
}
