import _ from "lodash"

/**
 * BEM is a useful way to organize CSS into blocks, elements, and modifiers.
 * However, adding a bunch of BEM classNames to an element can be kinda cubmersome,
 * especially when you start using modifiers. This BEM helper makes everything easier.
 *
 * For more info on BEM check out http://getbem.com/
 *
 * Examples:
 *
 * 1. Default usage
 * ```js
 *    const BLOCK = new BEM("button")
 *    BLOCK.toString()           // "button"
 *    BLOCK.e("text").toString() // "button__text"
 *    BLOCK.m("blue").toString() // "button button--blue"
 * ```
 *
 * 2. In React
 * ```jsx
 * // At the beginning of the file
 * const BLOCK = new BEM("button")
 *
 * function Button(props) {
 *   return (
 *     <button className={BLOCK.m(props.color)}>
 *       <span className={BLOCK.e("text")}>{props.children}</span>
 *     </button>
 *   )
 * }
 * ```
 */

class BEM {
  constructor(block, elements = [], modifiers = [], extraClasses = []) {
    this.block = block
    this.elements = elements
    this.modifiers = modifiers
    this.extraClasses = extraClasses
  }

  e(...elements) {
    return this._cloneWithAttr("elements", elements)
  }

  m(...modifiers) {
    return this._cloneWithAttr("modifiers", modifiers)
  }

  addClass(...extraClasses) {
    return this._cloneWithAttr("extraClasses", extraClasses)
  }

  toString() {
    const baseClass = [this.block, ...this.elements].join("__")
    const modClasses = _.map(this.modifiers, (mod) => `${baseClass}--${mod}`)
    return _.trim([baseClass, ...modClasses, this.extraClasses].join(" "))
  }

  _cloneWithAttr(attr, addArray) {
    addArray = _.compact(addArray)
    return _.assign(_.clone(this), {
      [attr]: [...this[attr], ...addArray],
    })
  }
}

export default BEM
