import Request from "utils/Request"
import { assembleResource } from "utils/providers/assemble-resource"
import { organizationEndpoint } from "utils/endpoints"

export const REQUEST_ORGANIZATION = "REQUEST_ORGANIZATION"
export const RECEIVE_ORGANIZATION = "RECEIVE_ORGANIZATION"
export const INCREMENT_ORGANIZATION_TEAM_NUMBER =
  "INCREMENT_ORGANIZATION_TEAM_NUMBER"
export const DECREMENT_ORGANIZATION_TEAM_NUMBER =
  "DECREMENT_ORGANIZATION_TEAM_NUMBER"
export const RESET_ORGANIZATION = "RESET_ORGANIZATION"

const requestOrganization = () => {
  return {
    type: REQUEST_ORGANIZATION,
  }
}

const receiveOrganization = (json) => {
  return {
    type: RECEIVE_ORGANIZATION,
    organization: assembleResource(json.data),
  }
}

export const fetchOrganization = () => {
  return (dispatch) => {
    dispatch(requestOrganization())
    const onSuccess = (result) => {
      dispatch(receiveOrganization(result.json))
    }

    return Request.get({ endpoint: organizationEndpoint(), onSuccess })
  }
}

export const incrementNumberOfTeams = () => {
  return {
    type: INCREMENT_ORGANIZATION_TEAM_NUMBER,
  }
}

export const decrementNumberOfTeams = () => {
  return {
    type: DECREMENT_ORGANIZATION_TEAM_NUMBER,
  }
}

export const resetOrganization = () => {
  return {
    type: RESET_ORGANIZATION,
  }
}
