import PropTypes from "prop-types"
import React from "react"

const Alert = ({ children, type, customClassName }) => {
  const className = `alert alert-${type} text-center ${customClassName}`
  return (
    <div className={className} role="alert">
      {children}
    </div>
  )
}

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  customClassName: PropTypes.string,
}

export default Alert
