import "whatwg-fetch"
import Session from "app/session"

const headers = () => ({
  "Content-Type": "application/vnd.api+json",
  "Session-Token": Session.token(),
})

const fetchJSON = (url, options) => {
  return fetch(url, options)
    .then((response) => {
      return response
        .json()
        .then((json) => Promise.resolve({ response, json }))
        .catch(() => Promise.resolve({ response }))
    })
    .catch((response) => Promise.resolve({ response }))
}

const deleteJSON = (url) => {
  return fetchJSON(url, {
    method: "DELETE",
    headers: headers(),
  })
}

const getJSON = (url) => fetchJSON(url, { headers: headers() })

const postJSON = (url, body) => {
  return fetchJSON(url, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify(body),
  })
}

const patchJSON = (url, body) => {
  return fetchJSON(url, {
    method: "PATCH",
    headers: headers(),
    body: JSON.stringify(body),
  })
}

export { deleteJSON, getJSON, postJSON, patchJSON }
