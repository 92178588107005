import PropTypes from "prop-types"
import React from "react"

const assemble = (partnerAdmin) => {
  return {
    id: partnerAdmin["id"],
    email: partnerAdmin["email"],
    firstName: partnerAdmin["first_name"],
    lastName: partnerAdmin["last_name"],
  }
}

const schema = PropTypes.shape({
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
})

export default {
  assemble,
  schema,
}
