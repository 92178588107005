import React from "react"
import PropTypes from "prop-types"

const MobileControlPanel = ({ children, header }) => {
  return (
    <div className="m-control-panel hidden-sm hidden-md hidden-lg">
      {header && <div className="m-control-panel-header">{header}</div>}

      <div className="m-control-panel-inner">{children}</div>
    </div>
  )
}

MobileControlPanel.defaultProps = {
  header: "",
}

MobileControlPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  header: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
}

export default MobileControlPanel
