import { deleteJSON, getJSON, patchJSON, postJSON } from "utils/fetch-helpers"

const doNothing = () => {}

const request =
  (action) =>
  ({ endpoint, payload, onSuccess = doNothing, onFailure = doNothing }) => {
    return action(endpoint, payload)
      .then((result) => {
        if (result.response.ok) {
          onSuccess(result)
          return result
        } else {
          onFailure(result)
          return result
        }
      })
      .catch((error) => {
        console.error(error)
        onFailure(error)
        return error
      })
  }

const Request = {
  get: request(getJSON),
  destroy: request(deleteJSON),
  post: request(postJSON),
  patch: request(patchJSON),
}

export default Request
