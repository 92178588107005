import PropTypes from "prop-types"
import React from "react"

const RESOURCE_TYPE = "coaches"

const assemble = (data) => {
  const teamIds = () => {
    const relation = data.relationships
    if (relation.teams) {
      return relation.teams.data.map((team) => {
        return team.id
      })
    } else if (relation.team) {
      return [relation.team.data.id]
    } else {
      return []
    }
  }

  return {
    id: data.id,
    name: data.attributes["first-name"] + " " + data.attributes["last-name"],
    firstName: data.attributes["first-name"],
    lastName: data.attributes["last-name"],
    email: data.attributes["email"],
    phone: data.attributes["phone"],
    positionTitle: data.attributes["position-title"],
    photoUrl: data.attributes["photo-url"],
    admin: data.attributes["admin"],
    verified: data.attributes["verified"],
    isPrimaryContact: data.attributes["primary-contact"],
    teamIds: teamIds(),
  }
}

const schema = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  positionTitle: PropTypes.string.isRequired,
  photoUrl: PropTypes.string.isRequired,
  isPrimaryContact: PropTypes.bool.isRequired,
})

export default {
  RESOURCE_TYPE,
  assemble,
  schema,
}
