import PropTypes from "prop-types"
import React from "react"
import OrgPartnerAdmin from "organizations/org-partner-admin"

const RESOURCE_TYPE = "high-schools"

const assemble = (data) => {
  return {
    id: data.id,
    slug: data.attributes["slug"],
    address: data.attributes["address"],
    city: data.attributes["city"],
    email: data.attributes["email"],
    name: data.attributes["name"],
    phone: data.attributes["phone"],
    state: data.attributes["state"],
    website: data.attributes["website"],
    zipCode: data.attributes["zip-code"],
    logoUrl: data.attributes["logo-url"],
    sports: data.attributes["sports"] || [],
    sportNames: (data.attributes["sports"] || []).map(
      (sport) => sport.sport_name
    ),
    contractsStatus: data.attributes["contracts-status"],
    numberOfTeams: data.attributes["number-of-teams"],
    numberOfAthletes: data.attributes["number-of-athletes"],
    numberOfContracts: data.attributes["number-of-contracts"],
    primaryContactEmail: data.attributes["primary-contact"]["email"],
    primaryContactName: data.attributes["primary-contact"]["name"],
    affiliatedAdmins: (data.attributes["affiliated-partner-admins"] || []).map(
      OrgPartnerAdmin.assemble
    ),
    freeAgreementStatus: data.attributes["free-agreement-status"],
    highSchoolId: data.attributes["high-school-id"],
    verified: data.attributes["verified?"],
  }
}

const schema = PropTypes.shape({
  id: PropTypes.string.isRequired,
  address: PropTypes.string,
  city: PropTypes.string,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  state: PropTypes.string,
  website: PropTypes.string.isRequired,
  zipCode: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
  sportNames: PropTypes.arrayOf(PropTypes.string),
  highSchoolId: PropTypes.number.isRequired,
  contractsStatus: PropTypes.string.isRequired,
  numberOfTeams: PropTypes.number,
  numberOfAthletes: PropTypes.number,
  numberOfContracts: PropTypes.number,
  primaryContactEmail: PropTypes.string,
  primaryContactName: PropTypes.string,
  affiliatedAdmins: PropTypes.arrayOf(OrgPartnerAdmin.schema),
  freeAgreementStatus: PropTypes.string,
  verified: PropTypes.bool.isRequired,
})

export default {
  RESOURCE_TYPE,
  assemble,
  schema,
}
