import React from "react"

import Range from "rc-slider/lib/Range"
import PropTypes from "prop-types"
import "rc-slider/assets/index.css"

export default class RangeSlider extends React.Component {
  constructor(props) {
    super(props)

    this.rangeFromMarks = this.rangeFromMarks.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  get rangeSliderMarks() {
    return Object.assign(
      ...this.props.values.map((value, i) => ({ [i]: { label: value[0] } }))
    )
  }

  get rangeMax() {
    return this.props.values.length - 1
  }

  get selectedRange() {
    return this.rangeFromMarks(this.props.selectedRange)
  }

  rangeFromMarks(marks) {
    const { values } = this.props

    return [
      values.findIndex((v) => v[1] === marks[0]),
      values.findIndex((v) => v[1] === marks[1]),
    ]
  }

  handleChange(range) {
    this.setState({ value: range })

    const low = this.props.values[range[0]][1]
    const high = this.props.values[range[1]][1]
    this.props.onChange(this.props.name, [low, high])
  }

  render() {
    return (
      <div className="range-slider" id={`ranger-slider-${this.props.name}`}>
        <h4>{this.props.label}</h4>
        <Range
          min={0}
          max={this.rangeMax}
          marks={this.rangeSliderMarks}
          value={[...this.selectedRange]}
          dots={false}
          allowCross={false}
          onChange={this.handleChange}
        />
      </div>
    )
  }
}

RangeSlider.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.arrayOf(
      (valuePair, index, componentName, location, propFullName) => {
        if (index === 0 && typeof valuePair[0] !== "string") {
          return new Error(
            `${propFullName} in ${componentName}, first item in value pair must be a string`
          )
        }
        if (index === 1 && typeof valuePair[1] !== "number") {
          return new Error(
            `${propFullName} in ${componentName}, second item in value pair must be a number`
          )
        }
      }
    )
  ).isRequired,
  selectedRange: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
}
