import _ from "lodash"
import PropTypes from "prop-types"
import React from "react"

import ButtonGroupSelector from "utils/forms/ButtonGroupSelector"
import RangeSlider from "utils/forms/RangeSlider"
import TreeSelector from "utils/forms/tree_selector/TreeSelector"
import { usaStatesByRegion } from "utils/usa-states"
import deepEqual from "deep-equal"
import Request from "utils/Request"
import { collegeMajorsEndpoint } from "utils/endpoints"

export default class CollegeFilterContainer extends React.Component {
  constructor(props) {
    super(props)

    this.getCollegeMajorData = this.getCollegeMajorData.bind(this)
    this.toggleFilters = this.toggleFilters.bind(this)
    this.handleFilterUpdated = this.handleFilterUpdated.bind(this)
    this.applyFilters = this.applyFilters.bind(this)
    this.extractIds = this.extractIds.bind(this)
    this.resetFilters = this.resetFilters.bind(this)
    this.scrollToResults = this.scrollToResults.bind(this)

    const { showFilters } = props

    this.state = {
      showFilters,
      filters: initialFilterState,
      appliedFilters: initialFilterState,
      applyButtonDisabled: true,
      hasAppliedFilters: false,
      majorsByCategory: {
        name: "Major Categories",
        id: "major_categories",
        childType: "Major Categories",
        children: [],
      },
    }
  }

  componentWillReceiveProps(nextProps, prevState) {
    const { showFilters } = nextProps

    if (showFilters) {
      this.setState({ showFilters })
    }
  }

  componentWillMount() {
    if (this.props.filters.stateCode) {
      const states = usaStatesByRegion.children
        .map((region) => region.children)
        .flat()
      this.setState({
        filters: {
          ...this.state.filters,
          stateCode: this.props.filters.stateCode
            .map((id) => _.find(states, { id }))
            .filter(Boolean),
        },
      })
    }
  }

  componentDidMount() {
    this.getCollegeMajorData()
  }

  toggleFilters() {
    this.setState((prevState) => ({ showFilters: !prevState.showFilters }))
  }

  scrollToResults() {
    const HEADER_HEIGHT = 60
    const resultsDiv =
      document
        .getElementsByClassName("college-filters-result")[0]
        .getBoundingClientRect().top - HEADER_HEIGHT

    window.scrollTo(0, resultsDiv)
  }

  handleFilterUpdated(name, value) {
    this.setState((prevState) => {
      const newFilterState = { ...prevState.filters }
      newFilterState[name] = value
      const filtersUnchanged = deepEqual(
        newFilterState,
        prevState.appliedFilters
      )
      return {
        filters: newFilterState,
        applyButtonDisabled: filtersUnchanged,
      }
    })
  }

  applyFilters() {
    const { onFilter, catchFilterChanges } = this.props

    this.setState({
      appliedFilters: this.state.filters,
      applyButtonDisabled: true,
    })

    onFilter(this.extractIds(this.state.filters))
    catchFilterChanges(true)

    this.scrollToResults()
  }

  extractIds(filters) {
    const result = {}
    Object.keys(filters).forEach(
      (key) =>
        (result[key] = [...(filters[key] || [])]
          .map((item) =>
            filterTransformers[key] ? filterTransformers[key](item) : item
          )
          .filter((item) => item !== null))
    )

    return result
  }

  resetFilters() {
    const { onClearFromParent, catchFilterChanges, onToggleScreen } = this.props

    this.setState({
      filters: initialFilterState,
      appliedFilters: initialFilterState,
      applyButtonDisabled: true,
    })

    onClearFromParent()
    catchFilterChanges(false)
    onToggleScreen()
  }

  getCollegeMajorData() {
    const onSuccess = (result) => {
      const majorsByCategory = JSON.parse(result.json.data)

      this.setState({
        majorsByCategory: {
          name: "Major Categories",
          id: "major_categories",
          childType: "Major Categories",
          children: majorsByCategory,
        },
      })
    }

    return Request.get({
      endpoint: collegeMajorsEndpoint(),
      onSuccess,
    })
  }

  isFilterApplied() {
    return !deepEqual(this.state.appliedFilters, initialFilterState)
  }

  render() {
    return (
      <section className="college-filters">
        {this.props.hasToggler && (
          <div className="row">
            <div className="col-xs-12 filter-results">
              <button
                className="btn btn-primary"
                type="Filter"
                onClick={this.toggleFilters}
              >
                <i
                  className={`fa fa-chevron-${
                    this.state.showFilters ? "up" : "down"
                  }`}
                  aria-hidden="true"
                />
                Define Search
              </button>
            </div>
          </div>
        )}

        {this.state.showFilters && (
          <div className="filters-open">
            <div className="row">
              <div className="col-xs-6">
                <ButtonGroupSelector
                  label="Division:"
                  name="division"
                  selected={this.state.filters["division"]}
                  buttons={[
                    { label: "NCAA I", key: "NCAA I" },
                    { label: "NCAA II", key: "NCAA II" },
                    { label: "NCAA III", key: "NCAA III" },
                    { label: "NAIA", key: "NAIA" },
                    { label: "JC", key: "JC" },
                  ]}
                  onChange={this.handleFilterUpdated}
                />
              </div>
              <div className="col-xs-6">
                <RangeSlider
                  label="Enrollment Size:"
                  name="enrollmentRange"
                  values={[
                    ["<1K", 0],
                    ["5K", 5000],
                    ["10K", 10000],
                    ["20K", 20000],
                    ["30K", 30000],
                    ["40K+", 500000],
                  ]}
                  selectedRange={this.state.filters["enrollmentRange"]}
                  onChange={this.handleFilterUpdated}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <ButtonGroupSelector
                  label="Public/Private School:"
                  name="publicPrivate"
                  selected={this.state.filters["publicPrivate"]}
                  buttons={[
                    { label: "Public", key: "PUBLIC" },
                    { label: "Private", key: "PRIVATE" },
                  ]}
                  onChange={this.handleFilterUpdated}
                />
              </div>
              <div className="col-xs-6">
                <RangeSlider
                  label="School tuition:"
                  name="tuitionRange"
                  values={[
                    ["$0", 0],
                    ["$5K", 5000],
                    ["$10K", 10000],
                    ["$20K", 20000],
                    ["$30K", 30000],
                    ["$40K+", 100000],
                  ]}
                  selectedRange={this.state.filters["tuitionRange"]}
                  onChange={this.handleFilterUpdated}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <ButtonGroupSelector
                  label="School Type(s):"
                  name="collegeType"
                  selected={this.state.filters["collegeType"]}
                  buttons={[
                    { label: "Military", key: "MILITARY" },
                    { label: "HBC", key: "HBC" },
                    { label: "Christian", key: "CHRISTIAN" },
                    { label: "Women's", key: "WOMEN" },
                    { label: "Men's", key: "MEN" },
                  ]}
                  onChange={this.handleFilterUpdated}
                />
              </div>
              <div className="col-xs-6">
                <ButtonGroupSelector
                  label="Academic Selectivity:"
                  name="academicStrength"
                  selected={this.state.filters["academicStrength"]}
                  buttons={[
                    { label: "Most Selective", key: "1" },
                    { label: "Selective", key: "2" },
                    { label: "Somewhat Selective", key: "3" },
                  ]}
                  onChange={this.handleFilterUpdated}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-6">
                <TreeSelector
                  name="stateCode"
                  label="State/Region:"
                  dataTree={usaStatesByRegion}
                  selectedNodes={this.state.filters["stateCode"]}
                  onChange={this.handleFilterUpdated}
                />
              </div>
              {this.props.hasCollegeMajor && (
                <div className="col-xs-6">
                  <TreeSelector
                    name="collegeMajor"
                    label="College Majors:"
                    dataTree={this.state.majorsByCategory}
                    selectedNodes={this.state.filters["collegeMajor"]}
                    isInitiallyVisible={false}
                    isToggleable={true}
                    onChange={this.handleFilterUpdated}
                  />
                </div>
              )}
            </div>

            <div className="row apply-filters form-buttons">
              <button
                className={`btn btn-primary btn-outlined ${
                  this.isFilterApplied() ? "is-applied" : ""
                }`}
                onClick={this.resetFilters}
              >
                Clear
              </button>
              <button
                className="btn btn-primary"
                type="submit"
                onClick={this.applyFilters}
                disabled={this.state.applyButtonDisabled}
              >
                {this.props.submitBtnText}
              </button>
            </div>
          </div>
        )}
      </section>
    )
  }
}

CollegeFilterContainer.defaultProps = {
  hasToggler: true,
  hasCollegeMajor: true,
  submitBtnText: "Search",
  onToggleScreen: () => {},
  filters: {},
}

CollegeFilterContainer.propTypes = {
  showFilters: PropTypes.bool.isRequired,
  catchFilterChanges: PropTypes.func.isRequired,
  onClearFromParent: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  onToggleScreen: PropTypes.func,
  hasToggler: PropTypes.bool,
  hasCollegeMajor: PropTypes.bool,
  submitBtnText: PropTypes.string,
}

const initialFilterState = {
  collegeType: [],
  publicPrivate: [],
  academicStrength: [],
  stateCode: [],
  collegeMajor: [],
  division: [],
  tuitionRange: [0, 100000],
  enrollmentRange: [0, 500000],
}

const filterTransformers = {
  stateCode: (node) => node.id,
  collegeMajor: (node) => (node.children ? null : node.id),
}
