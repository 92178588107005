import PropTypes from "prop-types"
import React, { Component } from "react"

import MobileControlScreen from "utils/mobile-control/MobileControlScreen"
import MobileControlPanel from "utils/mobile-control/MobileControlPanel"
import MobileControlPanelButton from "utils/mobile-control/MobileControlPanelButton"

import CollegeFilterContainer from "colleges/CollegeFilterContainer"

class CollegeMobileControlPanel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      screens: {
        collegeFilter: false,
      },
    }

    this.toggleScreen = this.toggleScreen.bind(this)
    this.handleFilter = this.handleFilter.bind(this)
  }

  toggleScreen(screen) {
    return () => {
      this.setState((prevState) => ({
        screens: {
          ...prevState.screens,
          [screen]: !prevState.screens[screen],
        },
      }))
    }
  }

  handleFilter(filters = {}) {
    const toggleCollegeFilter = this.toggleScreen("collegeFilter")

    toggleCollegeFilter()
    this.props.onFilter(filters)
  }

  render() {
    const { collegeFilter: isCollegeFilterOpen } = this.state.screens

    const { catchFilterChanges, onClearFromParent, appliedFilters } = this.props

    return (
      <div>
        <MobileControlPanel header={appliedFilters}>
          <MobileControlPanelButton
            onClick={this.toggleScreen("collegeFilter")}
          >
            Search Colleges
          </MobileControlPanelButton>
        </MobileControlPanel>
        <MobileControlScreen
          title="Search Colleges"
          isOpen={isCollegeFilterOpen}
          onToggleScreen={this.toggleScreen("collegeFilter")}
        >
          <CollegeFilterContainer
            hasToggler={false}
            hasCollegeMajor={false}
            submitBtnText={"Apply"}
            showFilters={isCollegeFilterOpen}
            catchFilterChanges={catchFilterChanges}
            onClearFromParent={onClearFromParent}
            onFilter={this.handleFilter}
            onToggleScreen={this.toggleScreen("collegeFilter")}
          />
        </MobileControlScreen>
      </div>
    )
  }
}

CollegeMobileControlPanel.propTypes = {
  onFilter: PropTypes.func.isRequired,
  catchFilterChanges: PropTypes.func.isRequired,
  onClearFromParent: PropTypes.func.isRequired,
  appliedFilters: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]).isRequired,
}

export default CollegeMobileControlPanel
