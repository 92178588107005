import PropTypes from "prop-types"
import React from "react"

import { exists } from "utils/js-helpers"

const emDash = "\u2014"

const format = (values) => {
  if (values) {
    return values.filter(exists).join(", ")
  } else {
    return emDash
  }
}

const formatEnrollment = (min, max) => {
  if (min || max) {
    return `${min || "N/A"} - ${max || "N/A"}`
  } else {
    return emDash
  }
}

const assemble = (preferences) => {
  return {
    academicSelectivity: format(preferences["academic-selectivity"]),
    athleticSelectivity: format(preferences["athletic-selectivity"]),
    collegeSetting: format(preferences["college-setting"]),
    collegeType: format(preferences["college-type"]),
    enrollmentRange: formatEnrollment(
      preferences["enrollment-min"],
      preferences["enrollment-max"]
    ),
    location: preferences["location"],
    major: format(preferences["major"]),
  }
}

const assembleMapData = (locationPreferences) => {
  return locationPreferences.reduce((data, location) => {
    data[location.state] = { fillKey: location.preference }
    return data
  }, {})
}

const schema = PropTypes.shape({
  academicSelectivity: PropTypes.string,
  athleticSelectivity: PropTypes.string,
  collegeSetting: PropTypes.string,
  collegeType: PropTypes.string,
  enrollmentRange: PropTypes.string,
  location: PropTypes.array,
  major: PropTypes.string,
})

export default {
  assemble,
  assembleMapData,
  schema,
}
