import Session from "app/session"
import { generateMailtoLink, mailtoWithProps } from "utils/Mailto"

const NL = "%0A"

export const filterCoachesForSport = (coaches, sportId) => {
  return coaches.filter((coach) => coach.sportId.toString() === sportId)
}

const allCollegeCoachesForAthleteEmailProps = (athlete, college) => {
  const athleteProfileUrl = athlete.ncsaProfileUrl.split("?session_token")[0]
  const coachesForAthleteSport = filterCoachesForSport(
    college.coaches,
    athlete.sportId
  )
  const coachEmails = coachesForAthleteSport.map((coach) => coach.email)
  const coachNames = coachesForAthleteSport
    .map((coach) => coach.name)
    .join(", ")
  const athleteName = `${athlete.firstName} ${athlete.lastName}`
  return {
    email: coachEmails,
    headers: {
      cc: [athlete.ncsaEmail, athlete.email],
      subject: `${athlete.firstName} may be a good fit for you`,
      body: `
Hi ${coachNames},${NL}
${NL}
You can view ${athleteName}'s NCSA profile at: ${athleteProfileUrl}${NL}
${NL}
Sincerely,${NL}
${Session.userFirstName()} ${Session.userLastName()}${NL}
      `,
    },
  }
}

const allCollegeCoachesForAthleteEmailTemplate = (athlete, college) => {
  const { email, headers } = allCollegeCoachesForAthleteEmailProps(
    athlete,
    college
  )
  return generateMailtoLink(email, headers)
}

export const MailtoAllCollegeCoachesForAthlete = mailtoWithProps(
  ({ athlete, college }) =>
    allCollegeCoachesForAthleteEmailTemplate(athlete, college)
)

export default allCollegeCoachesForAthleteEmailTemplate
