const usaStates = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "American Samoa",
    abbreviation: "AS",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Guam",
    abbreviation: "GU",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Palau",
    abbreviation: "PW",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
]

const usaStatesByRegion = {
  name: "USA",
  id: "USA",
  type: "Country",
  childType: "Regions",
  children: [
    {
      name: "Northeast",
      id: "Northeast",
      type: "Region",
      childType: "States",
      children: [
        {
          name: "Connecticut",
          id: "CT",
          type: "State",
        },
        {
          name: "Maine",
          id: "ME",
          type: "State",
        },
        {
          name: "Massachusetts",
          id: "MA",
          type: "State",
        },
        {
          name: "New Hampshire",
          id: "NH",
          type: "State",
        },
        {
          name: "New Jersey",
          id: "NJ",
          type: "State",
        },
        {
          name: "New York",
          id: "NY",
          type: "State",
        },
        {
          name: "Pennsylvania",
          id: "PA",
          type: "State",
        },
        {
          name: "Rhode Island",
          id: "RI",
          type: "State",
        },
        {
          name: "Vermont",
          id: "VT",
          type: "State",
        },
      ],
    },
    {
      name: "South",
      id: "South",
      type: "Region",
      childType: "States",
      children: [
        {
          name: "Alabama",
          id: "AL",
          type: "State",
        },
        {
          name: "Arkansas",
          id: "AR",
          type: "State",
        },
        {
          name: "Delaware",
          id: "DE",
          type: "State",
        },
        {
          name: "District Of Columbia",
          id: "DC",
          type: "State",
        },
        {
          name: "Florida",
          type: "State",
          id: "FL",
        },
        {
          name: "Georgia",
          id: "GA",
          type: "State",
        },
        {
          name: "Kentucky",
          id: "KY",
          type: "State",
        },
        {
          name: "Louisiana",
          id: "LA",
          type: "State",
        },
        {
          name: "Maryland",
          id: "MD",
          type: "State",
        },
        {
          name: "Mississippi",
          id: "MS",
          type: "State",
        },
        {
          name: "North Carolina",
          id: "NC",
          type: "State",
        },
        {
          name: "Oklahoma",
          id: "OK",
          type: "State",
        },
        {
          name: "South Carolina",
          id: "SC",
          type: "State",
        },
        {
          name: "Tennessee",
          id: "TN",
          type: "State",
        },
        {
          name: "Texas",
          id: "TX",
          type: "State",
        },
        {
          name: "Virginia",
          id: "VA",
          type: "State",
        },
        {
          name: "West Virginia",
          id: "WV",
          type: "State",
        },
      ],
    },
    {
      name: "Midwest",
      id: "Midwest",
      type: "Region",
      childType: "States",
      children: [
        {
          name: "Illinois",
          id: "IL",
          type: "State",
        },
        {
          name: "Indiana",
          id: "IN",
          type: "State",
        },
        {
          name: "Iowa",
          id: "IA",
          type: "State",
        },
        {
          name: "Kansas",
          id: "KS",
          type: "State",
        },
        {
          name: "Michigan",
          id: "MI",
          type: "State",
        },
        {
          name: "Minnesota",
          id: "MN",
          type: "State",
        },
        {
          name: "Missouri",
          id: "MO",
          type: "State",
        },
        {
          name: "Nebraska",
          id: "NE",
          type: "State",
        },
        {
          name: "North Dakota",
          id: "ND",
          type: "State",
        },
        {
          name: "Ohio",
          id: "OH",
          type: "State",
        },
        {
          name: "South Dakota",
          id: "SD",
          type: "State",
        },
        {
          name: "Wisconsin",
          id: "WI",
          type: "State",
        },
      ],
    },
    {
      name: "West",
      id: "West",
      type: "Region",
      childType: "States",
      children: [
        {
          name: "Alaska",
          id: "AK",
          type: "State",
        },
        {
          name: "Arizona",
          id: "AZ",
          type: "State",
        },
        {
          name: "California",
          id: "CA",
          type: "State",
        },
        {
          name: "Colorado",
          id: "CO",
          type: "State",
        },
        {
          name: "Hawaii",
          id: "HI",
          type: "State",
        },
        {
          name: "Idaho",
          type: "State",
          id: "ID",
        },
        {
          name: "Montana",
          id: "MT",
          type: "State",
        },
        {
          name: "Nevada",
          id: "NV",
          type: "State",
        },
        {
          name: "New Mexico",
          id: "NM",
          type: "State",
        },
        {
          name: "Oregon",
          id: "OR",
          type: "State",
        },
        {
          name: "Utah",
          id: "UT",
          type: "State",
        },
        {
          name: "Washington",
          id: "WA",
          type: "State",
        },
        {
          name: "Wyoming",
          id: "WY",
          type: "State",
        },
      ],
    },
  ],
}

export { usaStates, usaStatesByRegion }
