export const SET_ALERTS = "SET_ALERTS"
export const REMOVE_ALERTS = "REMOVE_ALERTS"

export const setAlerts = (alerts) => {
  return {
    type: SET_ALERTS,
    alerts,
  }
}

export const removeAlerts = () => {
  return {
    type: REMOVE_ALERTS,
  }
}
