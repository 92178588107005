import PropTypes from "prop-types"
import React from "react"

const RESOURCE_TYPE = "organization-accounts"

const assemble = (data) => {
  return {
    id: data.id,
    accountHolderName: data.attributes["account-holder-name"],
    cardType: data.attributes["card-type"],
    expirationDate: `${data.attributes["expiration-month"]}/${data.attributes["expiration-year"]}`,
    last4: data.attributes["last-four"],
    primary: data.attributes["primary-account"],
    client_token: data.attributes["token"],
  }
}

const schema = PropTypes.shape({
  id: PropTypes.string.isRequired,
  accountHolderName: PropTypes.string.isRequired,
  cardType: PropTypes.string,
  last4: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
})

export default {
  RESOURCE_TYPE,
  assemble,
  schema,
}
