import { join, filter } from "./endpoint-params"
import config from "./env-config"
import Session from "../app/session"
import qs from "qs"

const baseURL = config.backendBaseURL
const socketURL = config.webSocketURL
const frontendBaseURL = config.frontendBaseURL

const webSocketEndpoint = () => {
  return socketURL()
}

const activeCollegesEndpoint = (sportId) => {
  return `${baseURL()}/active_colleges/${sportId}`
}
const athleteEndpoint = (id) => {
  return `${baseURL()}/athletes/${id}`
}
const athleteUpgradeEndpoint = (id) => {
  return `${baseURL()}/athletes/${id}/grant_features`
}
const athleteEnrollEndpoint = (id) => {
  return `${baseURL()}/athletes/${id}/offer_to_enroll`
}
const athleteActivityEndpoint = (id) => {
  return `${baseURL()}/athletes/${id}/activity`
}
const athleteEvaluationEndpoint = (id) => {
  return `${baseURL()}/athletes/${id}/evaluation`
}
const athleteVideoEndpoint = (id) => {
  return `${baseURL()}/athletes/${id}/add_video`
}
const athleteVerifyEndpoint = (id) => {
  return `${baseURL()}/athletes/${id}/verify`
}
const athleteInviteSingleEndpoint = (id) => {
  return `${baseURL()}/athletes/${id}/invite_single_athlete`
}
const athleteInviteAllEndpoint = (id) => {
  return `${baseURL()}/organizations/${id}/invite_all_athletes`
}
const athleteCheckRssAllEndpoint = (id) => {
  return `${baseURL()}/organizations/${id}/check_rss_all_athletes`
}
const athleteCheckRssSingleEndpoint = (id) => {
  return `${baseURL()}/athletes/${id}/check_rss_single_athlete`
}
const athleteKeyStatsEndpoint = (id) => {
  return `${baseURL()}/athletes/${id}/key_stats`
}
const athletePreferencesEndpoint = (id) => {
  return `${baseURL()}/athletes/${id}/preferences`
}
const athleteProfileEndpoint = (id) => {
  return `${baseURL()}/athletes/${id}/profile`
}
const athleteTeamsEndpoint = (id) => {
  return `${baseURL()}/athletes/${id}/relationships/teams`
}
const athleteCollegeRecommendationEndpoint = (id) => {
  return `${baseURL()}/athletes/${id}/recommendation`
}
const athleteIndexEndpoint = ({ organizationId, teamId } = {}) => {
  const params = join([filter("team_id", teamId)])
  return `${organizationEndpoint(organizationId)}/athletes${params}`
}
const athleteIndexRefreshEndpoint = () => {
  return `${organizationEndpoint()}/refresh_athletes`
}
const athleteCreateBulkEndpoint = () => {
  return `${baseURL()}/athletes/create_bulk`
}
const organizationImportAthleteBulk = (organizationId) => {
  return `${baseURL()}/organizations/${organizationId}/import_athlete_bulk`
}
const athletePatchEmailsEndpoint = (id) => {
  return `${baseURL()}/athletes/${id}/patch_athlete_emails`
}
const athletesPublicEndpoint = (organizationId) => {
  return `${baseURL()}/public/organizations/${organizationId}/athletes`
}

const clubSearchEndpoint = (query) => {
  return `${baseURL()}/public/club_search?query=${query}`
}

const coachEndpoint = (coachId) => {
  return `${baseURL()}/coaches/${coachId}`
}
const coachIndexEndpoint = ({ teamId, orgId } = {}) => {
  const params = join([filter("team_id", teamId)])
  return `${organizationEndpoint(orgId)}/coaches${params}`
}
const collegeEndpoint = (collegeId) => {
  return `${organizationEndpoint()}/colleges/${collegeId}`
}
const collegeIndexEndpoint = ({ query, pageNumber, sportId } = {}) => {
  const filterQueryString = qs.stringify(
    {
      filter: { keywords: query },
      page: { number: pageNumber },
      sport_id: sportId,
    },
    {
      arrayFormat: "brackets",
    }
  )

  return `${organizationEndpoint()}/colleges?${filterQueryString}`
}
const collegeFilterEndpoint = ({ filters, pageNumber, sportId } = {}) => {
  const filterQueryString = qs.stringify(
    {
      filter: filters,
      page: { number: pageNumber },
      sport_id: sportId,
    },
    {
      arrayFormat: "brackets",
    }
  )

  return `${organizationEndpoint()}/colleges?${filterQueryString}`
}

const collegeMajorsEndpoint = () => {
  return `${baseURL()}/colleges/majors`
}
const deactivateAthleteEndpoint = (athleteId) => {
  return `${baseURL()}/athletes/${athleteId}/deactivate`
}
const evaluationGuidelinesEndpoint = (sportId) => {
  return `${baseURL()}/organization_sports/${sportId}/evaluation_guidelines`
}
const eventTrackingEndpoint = () => {
  return `${baseURL()}/coach_event_trackings`
}
const highSchoolLocatorEndpoint = (zip) => {
  const domain = config.v1DomainName
  const host = domain().includes("localhost") ? domain("staging") : domain()
  const base = `${config.httpProtocol()}${host}`

  return `${base}/clientrms/api/highschool/locator?zip=${zip}`
}
const highSchoolPublicEndpoint = (highSchoolId) => {
  return `${baseURL()}/public/high_schools/${highSchoolId}`
}
const sportIndexEndpoint = () => {
  return `${baseURL()}/ncsa_sports`
}
const organizationEndpoint = (organizationId) => {
  return `${baseURL()}/organizations/${
    organizationId || Session.organizationId()
  }`
}
const organizationFreeAcceptanceEndpoint = (id) => {
  return `${baseURL()}/organizations/${id}/organization_free_agreement`
}
const organizationContractEndpoint = (id) => {
  return `${baseURL()}/organization_contracts/${id}`
}
const organizationAccountEndpoint = (id) => {
  return `${organizationEndpoint(id)}/organization_accounts.json`
}
const clientTokenEndpoint = (id) => {
  return `${baseURL()}/organization_accounts/${id}/client_token.json`
}
const organizationContractAcceptanceEndpoint = (id) => {
  return `${baseURL()}/organization_contracts/${id}/accept_terms_of_service`
}
const organizationContractCancelEndpoint = (id) => {
  return `${baseURL()}/organization_contracts/${id}/cancel`
}
const organizationContractIndexEndpoint = (id) => {
  return `${organizationEndpoint(id)}/organization_contracts`
}
const organizationContractPaymentsEndpoint = (contractId) => {
  return `${organizationContractEndpoint(contractId)}/payments`
}
const paymentReprocessEndpoint = (paymentId) => {
  return `${baseURL()}/organization_contract_payments/${paymentId}`
}
const organizationInvoiceEndpoint = (organizationId) => {
  return `${organizationEndpoint(organizationId)}/organization_invoices`
}
const organizationNotesIndexEndpoint = (id) => {
  return `${organizationEndpoint(id)}/organization_notes`
}
const organizationIndexEndpoint = (filters = {}, page = 1) => {
  const { chosenStatus, textQuery, orgType } = filters
  return `${partnerEndpoint()}/organizations?contracts_status=${chosenStatus}&text_query=${textQuery}&org_type=${orgType}&page=${page}`
}
const organizationPublicEndpoint = (orgSlug) => {
  return `${baseURL()}/public/organizations/${orgSlug}`
}
const organizationSportsEndpoint = () => {
  return `${organizationEndpoint()}/organization_sports`
}
const organizationContractLeadSourceEndpoint = () => {
  return `${partnerEndpoint()}/organization_contract_lead_source`
}
const updatesFeedEndpoint = (organizationId) => {
  return `${baseURL()}/updates_feed/${organizationId}`
}
const partnersIndexEndpoint = () => {
  return `${baseURL()}/partners`
}
const partnerEndpoint = () => {
  return `${baseURL()}/partners/${Session.partnerId()}`
}
const partnerAdminIndexEndpoint = () => {
  return `${partnerEndpoint()}/partner_admins`
}
const partnerAdminEndpoint = (partnerAdminId) => {
  return `${baseURL()}/partner_admins/${partnerAdminId}`
}
const partnerAdminContractIndexEndpoint = (partnerAdminId) => {
  return `${partnerAdminEndpoint(partnerAdminId)}/organization_contracts`
}
const passwordResetEndpoint = () => {
  return `${baseURL()}/password_resets`
}
const pricingTiersEndpoint = (sport_id) => {
  return `${baseURL()}/sports/${sport_id}/pricing_tiers`
}
const publicAthletesEndpoint = () => {
  return `${baseURL()}/public/athletes/`
}
const publicCoachIndexEndpoint = () => {
  return `${baseURL()}/public/coaches/`
}
const publicContactOrganizationEndpoint = (organizationId) => {
  return `${baseURL()}/public/organizations/${organizationId}/contact`
}
const publicOrganizationIndexEndpoint = () => {
  return `${baseURL()}/public/organizations/`
}
const signInEndpoint = () => {
  return `${baseURL()}/sign_in`
}
const sportsEngineSignInEndpoint = () => {
  return `${baseURL()}/sports_engine`
}

const sportsEngineClientIdEndpoint = () => {
  return `${baseURL()}/sports_engine/client_id`
}

const teamEndpoint = (teamId) => {
  return `${baseURL()}/teams/${teamId}`
}
const deleteTeamEndpoint = (teamId) =>
  `${baseURL()}/teams/${teamId}/delete_team`
const teamIndexEndpoint = () => {
  return `${organizationEndpoint()}/teams`
}
const tierSportsEndpoint = () => {
  return `${baseURL()}/pricing_tier_sports`
}
const multipleAthleteUploadTemplate = () => {
  return `${frontendBaseURL()}/team-edition/media/NCSA+Team+Edition+-+Athlete+Upload+Template.csv`
}
const updateAffiliatedAdminEndpoint = (orgId) => {
  return `${baseURL()}/organizations/${orgId}/update_affiliated_admin`
}
const updatePrimaryContactEndpoint = (orgId) => {
  return `${baseURL()}/organizations/${orgId}/update_primary_contact`
}

export {
  activeCollegesEndpoint,
  athleteActivityEndpoint,
  athleteCollegeRecommendationEndpoint,
  athleteCreateBulkEndpoint,
  athleteEndpoint,
  athleteUpgradeEndpoint,
  athleteEnrollEndpoint,
  athleteEvaluationEndpoint,
  athleteIndexEndpoint,
  athleteIndexRefreshEndpoint,
  athleteInviteAllEndpoint,
  athleteVerifyEndpoint,
  athleteInviteSingleEndpoint,
  athleteCheckRssAllEndpoint,
  athleteCheckRssSingleEndpoint,
  athleteKeyStatsEndpoint,
  athletePatchEmailsEndpoint,
  athletePreferencesEndpoint,
  athleteProfileEndpoint,
  athletesPublicEndpoint,
  athleteTeamsEndpoint,
  athleteVideoEndpoint,
  clubSearchEndpoint,
  coachEndpoint,
  coachIndexEndpoint,
  collegeEndpoint,
  collegeIndexEndpoint,
  collegeFilterEndpoint,
  collegeMajorsEndpoint,
  deactivateAthleteEndpoint,
  deleteTeamEndpoint,
  evaluationGuidelinesEndpoint,
  eventTrackingEndpoint,
  highSchoolLocatorEndpoint,
  highSchoolPublicEndpoint,
  multipleAthleteUploadTemplate,
  organizationAccountEndpoint,
  clientTokenEndpoint,
  organizationContractAcceptanceEndpoint,
  organizationContractEndpoint,
  organizationContractCancelEndpoint,
  organizationContractIndexEndpoint,
  organizationContractLeadSourceEndpoint,
  organizationContractPaymentsEndpoint,
  paymentReprocessEndpoint,
  organizationEndpoint,
  organizationFreeAcceptanceEndpoint,
  organizationIndexEndpoint,
  organizationInvoiceEndpoint,
  organizationNotesIndexEndpoint,
  organizationPublicEndpoint,
  organizationSportsEndpoint,
  updatesFeedEndpoint,
  partnerAdminContractIndexEndpoint,
  partnerAdminEndpoint,
  partnerAdminIndexEndpoint,
  partnerEndpoint,
  partnersIndexEndpoint,
  passwordResetEndpoint,
  pricingTiersEndpoint,
  publicAthletesEndpoint,
  publicContactOrganizationEndpoint,
  publicCoachIndexEndpoint,
  publicOrganizationIndexEndpoint,
  signInEndpoint,
  sportIndexEndpoint,
  sportsEngineClientIdEndpoint,
  sportsEngineSignInEndpoint,
  teamEndpoint,
  teamIndexEndpoint,
  tierSportsEndpoint,
  updateAffiliatedAdminEndpoint,
  updatePrimaryContactEndpoint,
  webSocketEndpoint,
  organizationImportAthleteBulk,
}
