import { combineReducers } from "redux"
import alerts from "store/reducers/alerts"
import buttonDisabled from "store/reducers/buttonDisabled"
import fetchTeams from "store/reducers/teams"
import currentOrganizations from "store/reducers/organizations"
import currentOrganization from "store/reducers/organization"
import currentContracts from "store/reducers/organization-contracts"
import dashboard from "store/reducers/dashboard"

const teamEditionReducers = combineReducers({
  alerts,
  buttonDisabled,
  fetchTeams,
  currentOrganization,
  currentOrganizations,
  currentContracts,
  dashboard,
})

export default teamEditionReducers
