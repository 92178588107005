import Raven from "raven-js"
import { environmentConfig } from "../utils/env-config"

export default () => {
  const isProduction = Boolean(
    environmentConfig.env.environment === "production"
  )

  const SENTRY_DSN_PUBLIC =
    "https://f973ba58e8564cdc86c32bf77384670f@sentry.io/1216484"

  // https://docs.sentry.io/clients/javascript/config/
  Raven.config(SENTRY_DSN_PUBLIC, {
    environment: isProduction ? "production" : "development",
    captureUnhandledRejections: true,
    maxBreadcrumbs: 25,
    autoBreadcrumbs: {
      console: true,
    },
    // send Sentry debugging to console in dev environment
    debug: !isProduction,
    // we only want to send prod errors to Sentry
    shouldSendCallback: () => isProduction,
  }).install()

  // improves memory performance
  // https://github.com/captbaritone/raven-for-redux/issues/50#issuecomment-355145593
  Raven.setDataCallback(null)

  return Raven
}
