import classnames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import Icon from "utils/Icon"

export default class Drawer extends React.Component {
  constructor(props) {
    super(props)

    this.toggleDrawer = this.toggleDrawer.bind(this)

    this.state = {
      isOpen: false,
    }
  }

  toggleDrawer() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }))
  }

  render() {
    const drawerClass = classnames({
      active: this.state.isOpen,
      drawer: true,
    })

    return (
      <div className={drawerClass}>
        <div className="drawer-toggle" onClick={this.toggleDrawer}>
          <Icon type="chevron-left" className="drawer-toggle__icon" />
        </div>
        <div className="drawer-content">
          <ul className="drawer-options" onClick={this.toggleDrawer}>
            {this.props.children}
          </ul>
        </div>
      </div>
    )
  }
}

Drawer.propTypes = {
  children: PropTypes.node.isRequired,
}
