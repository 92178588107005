import classnames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import Icon from "utils/Icon"

const Sort = ({
  sortOptions,
  currentSortField,
  ascendingOrder,
  handleSortBy,
}) => {
  const isActive = (option) => {
    return currentSortField === option.field
  }

  const isActiveDescending = (option) => {
    return isActive(option) && !ascendingOrder
  }

  const sortClassNames = (option) => {
    return classnames({
      sort: isActive(option),
      reverse: isActiveDescending(option),
    })
  }

  const handleClick = (event) => {
    const fieldName = event.target.id
    handleSortBy(fieldName)
  }

  return (
    <table className="table table--sortable-header">
      <thead>
        <tr>
          {sortOptions.map((option, index) => {
            return (
              <th
                key={index}
                id={option.field}
                className={sortClassNames(option)}
                onClick={handleClick}
              >
                {option.labelIcon && (
                  <Icon type={option.labelIcon} set={option.labelIconSet} />
                )}{" "}
                {option.label}
                {isActive(option) && (
                  // TODO: Clean this up and remove `sortClassNames`. This icon should be enough.
                  <Icon
                    type="chevron-up"
                    className={classnames("table--sortable-header__sort-icon", {
                      "table--sortable-header__sort-icon--is-reversed":
                        isActiveDescending(option),
                    })}
                  />
                )}
              </th>
            )
          })}
        </tr>
      </thead>
    </table>
  )
}

Sort.propTypes = {
  sortOptions: PropTypes.array.isRequired,
  currentSortField: PropTypes.string,
  ascendingOrder: PropTypes.bool,
  handleSortBy: PropTypes.func,
}

export default Sort
