import {
  REQUEST_CONTRACTS,
  RECEIVE_CONTRACTS,
  DELETE_CONTRACT,
  UPDATE_CONTRACT,
} from "store/actions/organization-contracts"

const contracts = (
  state = {
    isFetching: false,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case DELETE_CONTRACT:
      return Object.assign({}, state, {
        items: action.contracts,
        lastUpdated: action.updatedAt,
      })
    case UPDATE_CONTRACT: {
      const updatedContracts = state.items.map((contract) => {
        return contract.id === action.contract.id ? action.contract : contract
      })

      return Object.assign({}, state, {
        items: updatedContracts,
        lastUpdated: action.updatedAt,
      })
    }
    case REQUEST_CONTRACTS:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_CONTRACTS:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.contracts,
        lastUpdated: action.receivedAt,
      })
    default:
      return state
  }
}

export default contracts
