import { SET_ALERTS, REMOVE_ALERTS } from "store/actions/alerts"

const alerts = (state = [], action) => {
  switch (action.type) {
    case SET_ALERTS:
      return state.concat(action.alerts)
    case REMOVE_ALERTS:
      return []
    default:
      return state
  }
}

export default alerts
