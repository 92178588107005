import PropTypes from "prop-types"
import React, { Component } from "react"

import Alert from "Utils/Alert"
import Icon from "Utils/Icon"
import Request from "Utils/Request"

const LOADING_STATUS = {
  IN_PROGRESS: "inProgress",
  SUCCEEDED: "succeeded",
  FAILED: "failed",
}

export default class GetData extends Component {
  constructor(props) {
    super(props)

    this.handleFailure = this.handleFailure.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)

    this.state = {
      loadingStatus: LOADING_STATUS.IN_PROGRESS,
      data: null,
    }
  }

  componentWillUpdate(nextProps) {
    if (nextProps.endpoint !== this.props.endpoint) {
      this.props = nextProps
      this.getData()
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    this.setState({
      loadingStatus: LOADING_STATUS.IN_PROGRESS,
      data: null,
    })

    return Request.get({
      endpoint: this.props.endpoint,
      onSuccess: this.handleSuccess,
      onFailure: this.handleFailure,
    })
  }

  handleSuccess(result) {
    this.setState({
      loadingStatus: LOADING_STATUS.SUCCEEDED,
      data: result.json,
    })
  }

  handleFailure(e) {
    const { endpoint } = this.props
    this.setState({
      loadingStatus: LOADING_STATUS.FAILED,
    })
  }

  render() {
    if (this.state.loadingStatus === LOADING_STATUS.IN_PROGRESS) {
      return this.renderLoading()
    }
    if (this.state.loadingStatus === LOADING_STATUS.SUCCEEDED) {
      return this.renderSuccess()
    }
    return this.renderError()
  }

  renderLoading() {
    return (
      <Alert type="info">
        <Icon type="spinner fa-pulse fa-1x fa-fw" />
        &nbsp; Please wait while the data is being loaded...
      </Alert>
    )
  }

  renderSuccess() {
    return this.props.renderComponent(this.state.data)
  }

  renderError() {
    return (
      <Alert type="warning">
        <Icon type="exclamation-circle" />
        &nbsp; There was an issue retrieving the data.
      </Alert>
    )
  }
}

GetData.propTypes = {
  endpoint: PropTypes.string.isRequired,
  renderComponent: PropTypes.func.isRequired,
}
