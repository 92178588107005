import Request from "utils/Request"
import { organizationIndexEndpoint } from "utils/endpoints"
import { assembleResource } from "utils/providers/assemble-resource"
import { getFilterKey } from "store/reducers/organizations"

export const REQUEST_ORGANIZATIONS = "REQUEST_ORGANIZATIONS"
export const RECEIVE_ORGANIZATIONS = "RECEIVE_ORGANIZATIONS"
export const FAILURE_ORGANIZATIONS = "FAILURE_ORGANIZATIONS"
export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION"
export const ADD_ORGANIZATION = "ADD_ORGANIZATION"
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION"
export const UPDATE_ADMIN_FILTERS = "UPDATE_ADMIN_FILTERS"
export const ORGANIZATIONS_PAGE_CHANGE = "ORGANIZATIONS_PAGE_CHANGE"

const requestOrganizations = () => {
  return {
    type: REQUEST_ORGANIZATIONS,
  }
}

const failureOrganizations = () => {
  return {
    type: FAILURE_ORGANIZATIONS,
  }
}

const receiveOrganizations = ({ filters, page, data, totalPages }) => {
  return {
    type: RECEIVE_ORGANIZATIONS,
    filters,
    page,
    totalPages,
    organizations: assembleResource(data),
  }
}

export const fetchOrganizations = (filters, page) => {
  return (dispatch, getState) => {
    const {
      currentOrganizations: { byFilter, currentPage },
    } = getState()

    const key = getFilterKey(filters)

    const onSuccess = ({
      json: {
        data,
        meta: { "total-pages": totalPages },
      },
    }) => {
      dispatch(receiveOrganizations({ filters, page, data, totalPages }))
      dispatch(organizationsPageChange(page))
    }

    const onFailure = () => {
      dispatch(failureOrganizations())
    }

    if (byFilter[key] && byFilter[key]["pages"][page]) {
      dispatch(organizationsPageChange(page))
      dispatch(updateAdminFilters(filters))
    } else {
      dispatch(updateAdminFilters(filters))

      dispatch(requestOrganizations())
      return Request.get({
        endpoint: organizationIndexEndpoint(filters, page),
        onSuccess,
        onFailure,
      })
    }
  }
}

export const deleteOrganization = (id) => {
  return {
    type: DELETE_ORGANIZATION,
    id,
  }
}

export const updateOrganization = (organization) => {
  return {
    type: UPDATE_ORGANIZATION,
    organization,
  }
}

export const updateAdminFilters = (filters) => {
  return {
    type: UPDATE_ADMIN_FILTERS,
    filters,
  }
}

export const organizationsPageChange = (page) => {
  return {
    type: ORGANIZATIONS_PAGE_CHANGE,
    page,
  }
}
