import classnames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { randomId } from "utils/js-helpers"
import FieldErrors from "utils/forms/FieldErrors"

const SearchField = ({ errors, label, onChange, placeholder, type, value }) => {
  const id = randomId(10000)

  const handleChange = (event) => onChange(event.target.value)

  return (
    <fieldset
      className={classnames("form-group", { "has-error": errors.length > 0 })}
    >
      <label className="control-label" htmlFor={id}>
        {label}
      </label>

      <div className="search-input">
        <input
          autoFocus
          className="form-control"
          id={id}
          placeholder={placeholder}
          onChange={handleChange}
          type={type}
          value={value}
        />
      </div>

      <FieldErrors errors={errors} />
    </fieldset>
  )
}

SearchField.defaultProps = {
  type: "search",
  errors: [],
}

SearchField.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
}

export default SearchField
