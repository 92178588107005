import PropTypes from "prop-types"
import React from "react"

const assemble = (collegeCoach) => {
  return {
    name: collegeCoach["name"],
    position: collegeCoach["position"],
    phoneNumber: collegeCoach["phone-number"],
    email: collegeCoach["email"],
    sportName: collegeCoach["sport-name"],
    sportId: collegeCoach["sport-id"],
  }
}

const schema = PropTypes.shape({
  name: PropTypes.string,
  position: PropTypes.string,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  sportName: PropTypes.string,
})

export default {
  assemble,
  schema,
}
