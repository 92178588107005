import PropTypes from "prop-types"
import React from "react"

const RESOURCE_TYPE = "athlete-teams"

const assemble = (data) => {
  return {
    id: data.id,
    primary: data.attributes["primary"],
    active: data.attributes["active"],
    teamId: data.relationships["team"]["data"]["id"],
  }
}

const schema = PropTypes.shape({
  id: PropTypes.string.isRequired,
  primary: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  teamId: PropTypes.string.isRequired,
})

export default {
  RESOURCE_TYPE,
  assemble,
  schema,
}
