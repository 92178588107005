import PropTypes from "prop-types"
import React from "react"
import moment from "utils/immutable-moment"

const RESOURCE_TYPE = "organization-contracts"

const assemble = (data) => {
  return {
    id: data.id,
    startDate: moment(data.attributes["start-date"]),
    endDate: moment(data.attributes["end-date"]),
    modifier: data.attributes["modifier"],
    numberOfPayments: data.attributes["number-of-payments"],
    numberOfTeams: data.attributes["number-of-teams"],
    numberOfAthletes: data.attributes["number-of-athletes"],
    currentContractAmount: data.attributes["amount"],
    sportName: data.attributes["sport-name"],
    leadSource: data.attributes["lead-source"],
    paymentAccountId: data.attributes["payment-account-id"],
    acceptedBy: data.attributes["accepted-by"],
    acceptedAt: tryAcceptedAt(data.attributes["accepted-at"]),
    agreementUrl: data.attributes["agreement-url"],
    deletedAt: data.attributes["deleted-at"],
    organization: {
      id: data.attributes["organization"].id,
      name: data.attributes["organization"].name,
    },
  }
}

const tryAcceptedAt = (datetime) => {
  return datetime ? moment(datetime).format("MMMM Do, YYYY") : null
}

const schema = PropTypes.shape({
  id: PropTypes.string.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  modifier: PropTypes.string.isRequired,
  numberOfPayments: PropTypes.number.isRequired,
  numberOfTeams: PropTypes.number.isRequired,
  numberOfAthletes: PropTypes.number.isRequired,
  currentContractAmount: PropTypes.string.isRequired,
  paymentAccountId: PropTypes.number,
  acceptedBy: PropTypes.string,
  acceptedAt: PropTypes.string,
  agreementUrl: PropTypes.string,
})

export default {
  RESOURCE_TYPE,
  assemble,
  schema,
}
