import { REQUEST_TEAMS, RECEIVE_TEAMS } from "store/actions/teams"

const teams = (
  state = {
    isFetching: false,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case REQUEST_TEAMS:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_TEAMS:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.teams,
        lastUpdated: action.receivedAt,
      })
    default:
      return state
  }
}

export default teams
