import Loadable from "react-loadable"
import LoadableVisibility from "react-loadable-visibility/react-loadable"

export const Ldr = (loader, loading) =>
  Loadable({
    loader,
    loading: () => loading || null,
  })

export const VisibilityLdr = (loader, loading) =>
  LoadableVisibility({
    loader,
    loading: () => loading || null,
  })

export default Ldr
