import { browserHistory } from "react-router"
import cookie from "react-cookie"
import jwt_decode from "jwt-decode"
import Track from "utils/tracking/track"

import Routes from "app/routes"

const SESSION_KEY = "team_rms_session"
const IMPERSONATED_ORGANIZATION_KEY = "impersonated_organization"

const decodedSession = () => {
  if (!Session.signedIn()) {
    browserHistory.push(Routes.signIn())
    return {}
  }

  return jwt_decode(Session.token())
}

const Session = {
  clear: () =>
    [SESSION_KEY, IMPERSONATED_ORGANIZATION_KEY].map((cookie_key) => {
      cookie.remove(cookie_key, { path: "/" })
    }),

  create: (token) => cookie.save(SESSION_KEY, token, { path: "/" }),

  replace: (token) => {
    Session.clear()
    Session.create(token)
  },

  impersonate: (organization) => {
    if (Session.isPartnerAdmin()) {
      const simpleOrg = {
        id: organization.id,
        slug: organization.slug,
        name: organization.name,
        logoUrl: organization.logoUrl,
      }
      cookie.save(IMPERSONATED_ORGANIZATION_KEY, simpleOrg, { path: "/" })
    }
  },

  beOriginal: () => {
    cookie.remove(IMPERSONATED_ORGANIZATION_KEY, { path: "/" })
  },

  isAdmin: () => Session.isPartnerAdmin() || decodedSession().admin,

  isCoach: () => {
    return decodedSession().type === "coach"
  },

  isFreeCoach: () => {
    return decodedSession().is_free_coach
  },

  isVerifiedCoach: () => Session.isCoach() && decodedSession().verified,

  isFirstSignIn: () => {
    return decodedSession().first_sign_in
  },

  isImpersonating: () => {
    return Session.isPartnerAdmin() && !!Session.impersonatedOrganization()
  },

  isNcsaAdmin: () => {
    return decodedSession().is_ncsa_admin
  },

  isPartnerAdmin: () => {
    return decodedSession().type === "partner_admin"
  },

  isSportsEngine: () => {
    return decodedSession().is_sports_engine
  },

  signatureRequired: () => {
    return decodedSession().signature_required
  },

  impersonatedOrganization: () => {
    return cookie.load(IMPERSONATED_ORGANIZATION_KEY)
  },

  organizationId: () => {
    return Session.isImpersonating()
      ? Session.impersonatedOrganization().id
      : String(decodedSession().organization_id)
  },

  organizationSlug: () => {
    if (Session.isImpersonating()) {
      return Session.impersonatedOrganization().slug
    }

    if (Session.isCoach()) {
      return String(decodedSession().organization_slug)
    }
  },
  organizationName: () => {
    if (Session.isImpersonating()) {
      return Session.impersonatedOrganization().name
    }

    if (Session.isCoach()) {
      return String(decodedSession().organization_name)
    }
  },

  organizationType: () => String(decodedSession().organization_type),

  organizationLogoURL: () => {
    if (Session.isImpersonating()) {
      return Session.impersonatedOrganization().logoUrl
    }

    if (Session.isCoach()) {
      return decodedSession().org_logo_url
    }
  },

  partnerId: () => String(decodedSession().partner_id),

  primarySport: () => decodedSession().primary_sport,

  sessionType: () => String(decodedSession().type),

  signedIn: () => !!Session.token(),

  token: () => cookie.load(SESSION_KEY),

  userFirstName: () => decodedSession().user_first_name,

  userId: () => decodedSession().id,

  userLastName: () => decodedSession().user_last_name,

  trackCoachSession: () => {
    if (Session.isCoach()) {
      Track.setUserId(Session.userId())
    }
  },
}

export default Session
