import PropTypes from "prop-types"
import React from "react"

const ButtonGroupSelector = ({ label, name, buttons, selected, onChange }) => {
  const selectedButtons = new Set(selected)
  const buttonGroupId = `button-group-${name}`

  const handleButtonToggled = (buttonKey) => {
    if (selectedButtons.has(buttonKey)) {
      selectedButtons.delete(buttonKey)
    } else {
      selectedButtons.add(buttonKey)
    }

    // Needed because spread operator or Array.from not working for some reason:
    const array = []
    selectedButtons.forEach((v) => array.push(v))
    onChange(name, array)
  }

  return (
    <div className="button-group-selector" id={buttonGroupId}>
      <h4>{label}</h4>
      <div className="btn-group">
        {buttons.map((button) => {
          const toggleButton = () => handleButtonToggled(button.key)
          return (
            <Button
              label={button.label}
              buttonKey={button.key}
              key={button.key}
              isSelected={selectedButtons.has(button.key)}
              onToggle={toggleButton}
            />
          )
        })}
      </div>
    </div>
  )
}

ButtonGroupSelector.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
}

export default ButtonGroupSelector

const Button = ({ label, buttonKey, isSelected, onToggle }) => {
  const classNames = `btn btn-sm ${
    isSelected ? "btn-selected" : "btn-outlined"
  }`

  const toggleButton = () => {
    onToggle(buttonKey)
  }

  return (
    <button key={buttonKey} className={classNames} onClick={toggleButton}>
      {label}
    </button>
  )
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  buttonKey: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
}
