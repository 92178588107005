import { ENABLE_BUTTON, DISABLE_BUTTON } from "store/actions/toggleButton"

const buttonDisabled = (state = false, action) => {
  switch (action.type) {
    case DISABLE_BUTTON:
      return true
    case ENABLE_BUTTON:
      return false
    default:
      return state
  }
}
export default buttonDisabled
