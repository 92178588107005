import PropTypes from "prop-types"
import React from "react"

const RESOURCE_TYPE = "organization-sports"

const assemble = (data) => {
  return {
    id: data.id,
    name: data.attributes["sport-name"],
    sportId: String(data.attributes["sport-id"]),
    slug: data.attributes["slug"],
  }
}

const schema = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sportId: PropTypes.string.isRequired,
})

export default {
  RESOURCE_TYPE,
  assemble,
  schema,
}
