import PropTypes from "prop-types"
import React from "react"

import moment from "utils/immutable-moment"

const assemble = (athlete) => {
  return {
    id: athlete["id"],
    firstName: athlete["first-name"],
    lastName: athlete["last-name"],
    email: athlete["email"],
    ncsaEmail: athlete["ncsa-email"],

    athleteInterested: athlete["athlete-interested"],
    coachRecommended: athlete["team-coach-recommended"],
    gradYear: athlete["grad-year"],
    photoUrl: athlete["photo-url"],
    position: athlete["position"],
    teamName: athlete["team-name"],

    ncsaMatchPercentage: athlete["match-percentage"],
    isNcsaMatch: athlete["ncsa-match"],

    athleteReceivedEmailsCount:
      athlete["activity-summary"]["athlete-received-emails-count"],
    athleteSentEmailsCount:
      athlete["activity-summary"]["athlete-sent-emails-count"],
    coachFollowsCount: athlete["activity-summary"]["follows-count"],
    profileViewsCount: athlete["activity-summary"]["profile-views-count"],

    lastActiveCoachAction: athlete["last-activity"]["coach-recent-action"],
    lastActiveCoachFirstName: athlete["last-activity"]["coach-first-name"],
    lastActiveCoachLastName: athlete["last-activity"]["coach-last-name"],
    lastActiveCoachPosition: athlete["last-activity"]["coach-position"],
    lastActivityDate: moment(
      athlete["last-activity"]["last-activity-date"]
    ).startOf("day"),
    lastProfileViewDate: moment(
      athlete["last-activity"]["last-profile-view-date"]
    ).startOf("day"),
  }
}

const schema = PropTypes.shape({
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string,
  ncsaEmail: PropTypes.string,

  athleteInterested: PropTypes.bool.isRequired,
  coachRecommended: PropTypes.bool.isRequired,
  gradYear: PropTypes.string.isRequired,
  matchPercentage: PropTypes.number,
  photoUrl: PropTypes.string.isRequired,
  position: PropTypes.string,
  teamName: PropTypes.string.isRequired,

  athleteReceivedEmailsCount: PropTypes.number.isRequired,
  athleteSentEmailsCount: PropTypes.number.isRequired,
  coachFollowsCount: PropTypes.number.isRequired,
  profileViewsCount: PropTypes.number.isRequired,

  lastActiveCoachAction: PropTypes.string,
  lastActiveCoachFirstName: PropTypes.string,
  lastActiveCoachLastName: PropTypes.string,
  lastActiveCoachPosition: PropTypes.string,
  lastActivityDate: PropTypes.object.isRequired,
  lastProfileViewDate: PropTypes.object.isRequired,
})

export default {
  assemble,
  schema,
}
