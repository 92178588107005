import PropTypes from "prop-types"
import React from "react"
import classnames from "classnames"

class TreeNode extends React.Component {
  constructor(props) {
    super(props)

    this.renderDisclosureIcon = this.renderDisclosureIcon.bind(this)
    this.handleToggleCollapsed = this.handleToggleCollapsed.bind(this)
    this.handleToggleSelected = this.handleToggleSelected.bind(this)

    this.state = {
      collapsed: props.collapsed,
    }
  }

  get labelClassNames() {
    return {
      "selectable-label": true,
      "selected-label": this.isSelected,
    }
  }

  get isSelected() {
    const { selectedNodes, dataTree } = this.props
    return selectedNodes.map((node) => node.id).includes(dataTree.id)
  }

  get title() {
    const { dataTree } = this.props
    const name = dataTree.name
    const action = this.isSelected ? "Deselect" : "Select"
    const target = dataTree.children
      ? dataTree.childType
        ? `all ${dataTree.childType} in this ${dataTree.type}`
        : ""
      : dataTree.type
      ? `this ${dataTree.type}`
      : ""

    return `Click "${name}" to ${action} ${target}`
  }

  handleToggleCollapsed() {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }))
  }

  handleToggleSelected() {
    const nextIsSelected = !this.isSelected
    const rootNode = this.props.dataTree
    const childNodes = rootNode.children
    const nodes = childNodes ? [rootNode, ...childNodes] : [rootNode]

    this.props.onChange(nodes, nextIsSelected)
  }

  renderDisclosureIcon() {
    const [action, icon] = this.state.collapsed
      ? ["Expand", "▶"]
      : ["Collapse", "▼"]
    const title = `Click Arrow to ${action} this ${this.props.dataTree.type}`
    return (
      <span
        className="disclosure-icon"
        title={title}
        onClick={this.handleToggleCollapsed}
      >
        {icon}&nbsp;
      </span>
    )
  }

  render() {
    const rootDataNode = this.props.dataTree
    const hasChildren = !!rootDataNode.children
    const showChildren = hasChildren && !this.state.collapsed

    //
    // Render the current node and recursively render each child
    //
    return (
      <div className="tree-node" data-node-id={rootDataNode.id}>
        {hasChildren && this.renderDisclosureIcon()}
        <span
          className={classnames(this.labelClassNames)}
          title={this.title}
          onClick={this.handleToggleSelected}
        >
          {rootDataNode.name}
        </span>

        {showChildren && (
          <ul>
            {rootDataNode.children.map((subtree) => (
              <li key={subtree.id}>
                <TreeNode
                  dataTree={subtree}
                  onChange={this.props.onChange}
                  selectedNodes={this.props.selectedNodes}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }
}

/*
 * A tree type is a recursive data structure, i.e. it is a node
 * with a name and id, and optionally an array of children which
 * are in turn nodes/trees. Recursive structures like this cannot
 * be expressed with standard React PropTypes. However, since a
 * React prop type is ultimately just a function, we can use a
 * lazy-evaluated JS function to define a recursive prop type.
 */

/**
 * Lazy-evaluate the given function. Some fairly fancy JS.
 * We'll pass this a lambda that just returns the tree type.
 */
const lazyEval = function (fn) {
  return fn.bind.apply(fn, arguments)
}

/**
 * Use the lazy-evaluator to define a recursive Tree Type.
 */
const recursiveTreeType = lazyEval(() => {
  return TreeType
})

/**
 * The TreeType, with recursively-defined children.
 */
const TreeType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(recursiveTreeType),
}).isRequired

TreeNode.propTypes = {
  dataTree: TreeType,
  collapsed: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  selectedNodes: PropTypes.array,
}

TreeNode.defaultProps = {
  selectedNodes: [],
  collapsed: true,
}

export { TreeNode, TreeType }
