/* global process */

const path = "/api/team_edition"

class EnvironmentConfig {
  set env(config) {
    if (!this.config) {
      this.config = config
      Object.freeze(this.config)
      Object.freeze(this)
    } else {
      throw new Error("Environment Configuration has already been set")
    }
  }

  get env() {
    if (!this.config) {
      throw new Error("Environment Configuration has not been set")
    } else {
      return this.config
    }
  }
}

export const environmentConfig = new EnvironmentConfig()
export const environmentConfigUrl = "/config/env.json"

const domainName = () => {
  return environmentConfig.env.domainName
}

const v1DomainName = () => {
  return environmentConfig.env.v1DomainName
}

const feDomainName = () => {
  return environmentConfig.env.feDomainName
}

const canonicalDomain = () => {
  return environmentConfig.env.canonicalDomain
}

const httpProtocol = () => {
  return environmentConfig.env.httpProtocol
}

const socketProtocol = () => {
  return environmentConfig.env.socketProtocol
}

const backendBaseURL = () => {
  return `${httpProtocol()}${domainName()}${path}`
}

const frontendBaseURL = () => {
  return `${httpProtocol()}${feDomainName()}`
}

const webSocketURL = () => {
  return `${socketProtocol()}${domainName()}${path}/stream`
}

const gaTrackerId = () => {
  return environmentConfig.env.gaTrackerId
}

const sportsEngineBaseURL = () => {
  return environmentConfig.env.sportsEngineBaseURL
}

export default {
  canonicalDomain,
  backendBaseURL,
  frontendBaseURL,
  v1DomainName,
  webSocketURL,
  gaTrackerId,
  sportsEngineBaseURL,
  httpProtocol,
  domainName,
}
