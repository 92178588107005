export function definePromiseActionType(baseType) {
  return {
    LOADING: `${baseType}_LOADING`,
    SUCCESS: `${baseType}_SUCCESS`,
    FAILURE: `${baseType}_FAILURE`,
  }
}

export function definePromiseAction({ type, promiseCallback }) {
  return (dispatch) => {
    dispatch({
      type: type.LOADING,
    })
    return promiseCallback()
      .then((result) => {
        dispatch({
          type: type.SUCCESS,
          payload: result,
        })
        return result
      })
      .catch((error) => {
        dispatch({
          type: type.FAILURE,
          payload: error,
        })
        return error
      })
  }
}
