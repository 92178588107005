/* global ga */
import config from "utils/env-config"
import E from "utils/tracking/event-parameters"

const Track = {
  event: ({ action, category, label, value } = {}) => {
    ga("send", {
      hitType: "event",
      eventAction: action || E.ACTION.DEFAULT,
      eventCategory: category || E.CATEGORY.DEFAULT,
      eventLabel: label,
      eventValue: value,
    })
  },
  initializeTracking: () => {
    ga("create", config.gaTrackerId(), "auto")
    ga("require", "outboundLinkTracker")
    ga("require", "pageVisibilityTracker")
    ga("require", "urlChangeTracker")

    ga("send", "pageview")
  },

  setUserId: (coachId) => {
    ga("set", "userId", coachId.toString())
  },
}

export default Track
