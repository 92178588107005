import PropTypes from "prop-types"
import React from "react"

const RESOURCE_TYPE = "partner-admins"

const assemble = (data) => {
  return {
    id: data.id,
    email: data.attributes["email"],
    firstName: data.attributes["first-name"],
    lastName: data.attributes["last-name"],
  }
}

const schema = PropTypes.shape({
  id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
})

export default {
  RESOURCE_TYPE,
  assemble,
  schema,
}
