import PropTypes from "prop-types"
import React from "react"
import Icon from "utils/Icon"

const Paginator = ({ links, onPageChange }) => {
  const parsePageNumberFromURL = (url) => {
    if (url) {
      return Number(url.match(/page%5Bnumber%5D=(\d+)$/)[1])
    } else {
      return null
    }
  }

  const currentPageNumber = parsePageNumberFromURL(links.self)
  const lastPageNumber = parsePageNumberFromURL(links.last)

  const gotoPageNumber = (pageNumber) => {
    return () => {
      if (pageNumber && pageNumber >= 1 && pageNumber <= lastPageNumber) {
        onPageChange(pageNumber)
      }
    }
  }

  const nextPages = () => {
    const pages = []
    const remainingPages = lastPageNumber - currentPageNumber

    if (remainingPages > 0) {
      pages.push(currentPageNumber + 1)
    }

    if (remainingPages > 1) {
      pages.push(currentPageNumber + 2)
    }

    return pages
  }

  return (
    <ul className="pagination pagination-center">
      <li className="back">
        <a onClick={gotoPageNumber(currentPageNumber - 1)}>
          <Icon type="angle-left" />
        </a>
      </li>

      <li className="active">
        <a href="#">{currentPageNumber}</a>
      </li>

      {nextPages().map((pageNumber) => (
        <li key={pageNumber}>
          <a onClick={gotoPageNumber(pageNumber)}>{pageNumber}</a>
        </li>
      ))}

      <li className="forward">
        <a onClick={gotoPageNumber(currentPageNumber + 1)}>
          <Icon type="angle-right" />
        </a>
      </li>
    </ul>
  )
}

Paginator.propTypes = {
  links: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
}

export default Paginator
