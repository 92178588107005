import { browserHistory, IndexRoute, Route, Router } from "react-router"
import React from "react"
import { hot } from "react-hot-loader"

import Ldr from "utils/loader"

import {
  athleteEndpoint,
  athleteIndexEndpoint,
  collegeEndpoint,
  coachIndexEndpoint,
  organizationEndpoint,
  partnerAdminEndpoint,
} from "utils/endpoints"
import ResourceProvider from "utils/providers/ResourceProvider"
import CollegeSearch from "colleges/CollegeSearch"
import Routes from "app/routes"
import Session from "app/session"

const requireAuthorization = (nextState, replace) => {
  if (!Session.signedIn()) {
    replace({
      pathname: Routes.signIn(),
      state: { nextPathname: nextState.location.pathname },
    })
  }
}

const requireAdminAccess = (nextState, replace) => {
  if (Session.isAdmin() || Session.isSportsEngine()) {
    return
  }

  replace({
    pathname: Routes.pageNotAuthorized(),
    state: { nextPathname: nextState.location.pathname },
  })
}

const requirePartnerAdminAccess = (nextState, replace) => {
  if (!Session.isPartnerAdmin()) {
    replace({
      pathname: Routes.pageNotAuthorized(),
      state: { nextPathname: nextState.location.pathname },
    })
  }
}

const AppRouter = () => {
  const showAthleteProfile = (nextState, callback) => {
    const athleteProfileProvider = () =>
      ResourceProvider({
        component: Ldr(() =>
          import(
            /* webpackChunkName: "AthleteProfile" */ "athletes/AthleteProfile"
          )
        ),
        componentProps: { location: nextState.location },
        endpoint: athleteEndpoint(nextState.params.athleteId),
        resourceName: "athlete",
      })

    callback(null, athleteProfileProvider)
  }

  const LoadableFreeTermsOfServicePage = Ldr(() =>
    import(/* webpackChunkName: "FreeTOS" */ "app/FreeTermsOfServicePage")
  )

  const LoadableParterAdmin = Ldr(() =>
    import(
      /* webpackChunkName: "PartnerAdminDashboard" */ "app/PartnerAdministrationDashboard"
    )
  )

  const LoadableDashboardPage = Ldr(() =>
    import(
      /* webpackChunkName: "DashboardPage" */ "athletes/DashboardPage/DashboardPage"
    )
  )

  const LoadableActivityPage = Ldr(() =>
    import(/* webpackChunkName: "ActivityPage" */ "athletes/ActivityPage")
  )

  const LoadableAdministration = Ldr(() =>
    import(
      /* webpackChunkName: "Administration" */ "organization/Administration"
    )
  )

  const showDashboard = (nextState, callback) => {
    const dashboardProvider = () => {
      if (Session.isPartnerAdmin() && !Session.isImpersonating()) {
        return <LoadableParterAdmin />
      } else {
        return (
          <ResourceProvider
            component={LoadableDashboardPage}
            endpoint={athleteIndexEndpoint()}
            resourceName={"athletes"}
            componentProps={{
              showChangePassword:
                Session.isFirstSignIn() && !Session.isSportsEngine(),
            }}
          />
        )
      }
    }

    callback(null, dashboardProvider)
  }

  const showActivityPage = (nextState, callback) => {
    const dashboardProvider = () => (
      <ResourceProvider
        component={LoadableActivityPage}
        endpoint={athleteIndexEndpoint()}
        resourceName={"initialAthletes"}
      />
    )

    callback(null, dashboardProvider)
  }

  const showCollegeProfile = (nextState, callback) => {
    callback(null, () =>
      ResourceProvider({
        component: Ldr(() =>
          import(
            /* webpackChunkName: "CollegeProfile" */ "colleges/CollegeProfile"
          )
        ),
        endpoint: collegeEndpoint(nextState.params.collegeId),
        resourceName: "college",
      })
    )
  }

  const showOrganizationProfile = (nextState, callback) => {
    callback(null, () =>
      ResourceProvider({
        component: Ldr(() =>
          import(
            /* webpackChunkName: "OrgProfile" */ "organizations/OrganizationProfile"
          )
        ),
        endpoint: organizationEndpoint(nextState.params.organizationId),
        resourceName: "organization",
      })
    )
  }

  const showPartnerAdminProfile = (nextState, callback) => {
    callback(null, () =>
      ResourceProvider({
        component: Ldr(() =>
          import(
            /* webpackChunkName: "PartnerAdminProfile" */ "partner-admins/PartnerAdminProfile"
          )
        ),
        endpoint: partnerAdminEndpoint(nextState.params.partnerAdminId),
        resourceName: "partnerAdmin",
      })
    )
  }

  const showCoachSettings = (nextState, callback) => {
    callback(null, () =>
      ResourceProvider({
        component: Ldr(() =>
          import(
            /* webpackChunkName: "CoachSettings" */ "coaches/CoachSettings"
          )
        ),
        endpoint: coachIndexEndpoint(),
        resourceName: "coaches",
      })
    )
  }

  const showCollegeSearch = (nextState, callback) => {
    callback(null, CollegeSearch)
  }

  const scrollToTopOfPage = () => window.scrollTo(0, 0)

  return (
    <Router
      onUpdate={scrollToTopOfPage}
      history={browserHistory}
      key={Math.random()}
    >
      <Route
        path={Routes.signIn()}
        component={Ldr(() =>
          import(/* webpackChunkName: "LandingPage" */ "app/LandingPage")
        )}
      />
      <Route
        path={Routes.sportsEngineSignIn()}
        component={Ldr(() =>
          import(/* webpackChunkName: "SESignInPage" */ "app/SESignInPage")
        )}
      />
      <Route
        path={Routes.forgotPassword()}
        component={Ldr(() =>
          import(
            /* webpackChunkName: "ForgotPassword" */ "app/ForgotPasswordPage"
          )
        )}
      />
      <Route
        path={Routes.resetPassword()}
        component={Ldr(() =>
          import(
            /* webpackChunkName: "ResetPassword" */ "app/ResetPasswordPage"
          )
        )}
      />
      <Route
        path={Routes.pageNotAuthorized()}
        component={Ldr(() =>
          import(
            /* webpackChunkName: "NotAuthorized" */ "app/PageNotAuthorized"
          )
        )}
      />
      <Route
        path={Routes.termsOfService()}
        component={Ldr(() =>
          import(/* webpackChunkName: "TOS" */ "app/TermsOfServicePage")
        )}
      />
      <Route
        path={Routes.paymentAccount()}
        component={Ldr(() =>
          import(/* webpackChunkName: "CreditCard" */ "app/CreditCardPage")
        )}
      />
      <Route
        path={Routes.public(":sport", ":state", ":organizationName")}
        component={Ldr(() =>
          import(/* webpackChunkName: "PublicPage" */ "public-page/PublicPage")
        )}
      />
      <Route
        path={Routes.athleteSignUp(":orgSlug")}
        component={Ldr(() =>
          import(
            /* webpackChunkName: "AthleteSignUpPage" */ "athletes/AthleteSignUpPage"
          )
        )}
      />
      <Route
        path={Routes.root()}
        component={Ldr(() =>
          import(/* webpackChunkName: "Application" */ "app/App")
        )}
        onEnter={requireAuthorization}
      >
        <IndexRoute getComponent={showDashboard} />
        <Route
          path={Routes.administration()}
          component={Ldr(() =>
            import(
              /* webpackChunkName: "Administration" */ "organization/Administration"
            )
          )}
          onEnter={requireAdminAccess}
        />
        <Route
          path={Routes.rosterManagment()}
          component={Ldr(() =>
            import(
              /* webpackChunkName: "RosterManagement" */ "teams/RosterManagment"
            )
          )}
        />
        <Route path={Routes.findColleges()} getComponent={showCollegeSearch} />
        <Route path={Routes.activity()} getComponent={showActivityPage} />
        <Route
          path={Routes.athlete(":athleteId")}
          getComponent={showAthleteProfile}
        />
        <Route
          path={Routes.college(":collegeId")}
          getComponent={showCollegeProfile}
        />
        <Route
          path={Routes.organization(":organizationId")}
          getComponent={showOrganizationProfile}
          onEnter={requirePartnerAdminAccess}
        />
        <Route
          path={Routes.partnerAdmin(":partnerAdminId")}
          getComponent={showPartnerAdminProfile}
          onEnter={requirePartnerAdminAccess}
        />
        <Route
          path={Routes.userAccount()}
          component={Ldr(() =>
            import(
              /* webpackChunkName: "Administration" */ "organization/Administration"
            )
          )}
        />
        <Route
          path="*"
          component={Ldr(() =>
            import(/* webpackChunkName: "PageNotFound" */ "app/PageNotFound")
          )}
        />
      </Route>
    </Router>
  )
}

export default hot(module)(AppRouter)
