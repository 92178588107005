import { generateMailtoLink, mailtoWithProps } from "utils/Mailto"

const allCollegeCoachesEmailProps = (college) => ({
  email: college.coaches.map((coach) => coach.email),
  headers: {
    subject: "My athletes may be a good fit for you",
  },
})

const allCollegeCoachesEmailTemplate = (college) => {
  const { email, headers } = allCollegeCoachesEmailProps(college)
  return generateMailtoLink(email, headers)
}

export const MailtoAllCollegeCoaches = mailtoWithProps(({ college }) =>
  allCollegeCoachesEmailTemplate(college)
)

export default allCollegeCoachesEmailTemplate
