import PropTypes from "prop-types"
import React from "react"

import Athlete from "athletes/athlete"
import CollegeList from "colleges/CollegeList"
import CollegeRecommendationList from "colleges/CollegeRecommendationList"
import ResourceProvider from "utils/providers/ResourceProvider"

const CollegeSearchResults = ({
  athlete,
  endpoint,
  onAthleteUpdate,
  onOpenFilters,
  recommendation,
  searchQuery,
  filters,
  filtersApplied,
}) => {
  if (recommendation && recommendation.forRecommendation) {
    const componentProps = {
      athlete: athlete,
      recommendedCollegeIds: recommendation.recommendedCollegeIds,
      onAthleteUpdate: onAthleteUpdate,
    }

    return (
      <ResourceProvider
        component={CollegeRecommendationList}
        endpoint={endpoint}
        resourceName="colleges"
        componentProps={componentProps}
      />
    )
  } else {
    const componentProps = {
      query: searchQuery,
      filters,
      filtersApplied,
      onOpenFilters,
    }

    return (
      <ResourceProvider
        component={CollegeList}
        endpoint={endpoint}
        resourceName="colleges"
        componentProps={componentProps}
        includeResponsePayload={true}
      />
    )
  }
}

CollegeSearchResults.propTypes = {
  athlete: Athlete.schema,
  endpoint: PropTypes.string.isRequired,
  onAthleteUpdate: PropTypes.func,
  searchQuery: PropTypes.string.isRequired,
  onOpenFilters: PropTypes.func.isRequired,
  filters: PropTypes.object,
  recommendation: PropTypes.shape({
    forRecommendation: PropTypes.bool,
    recommendedCollegeIds: PropTypes.array,
  }),
  filtersApplied: PropTypes.bool,
}

export default CollegeSearchResults
