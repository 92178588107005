import { datadogRum } from "@datadog/browser-rum"
import { environmentConfig } from "../utils/env-config"

const DATADOG_RUM_CLIENT_TOKEN = "pubd491459c324d62779c1b33ac0a6190c8"
const DATADOG_RUM_APPLICATION_ID = "937cbb5b-8e9c-49bd-b5d7-d6c06c5d7b59"

const setupDataDogRUM = () => {
  // https://docs.datadoghq.com/agent/docker/?tab=standard#environment-variables
  if (
    ["production", "staging"].indexOf(environmentConfig.env.environment) > -1
  ) {
    // banking-info-regex-start
    // This regex was created by looking at keywords we display in our front-end.
    var bankingInfoRegex = /.*(card|credit|bank|account|routing|ach).*/gim
    var bankingInfoReplacement = "[BANKING INFO REDACTED]"
    // banking-info-regex-end

    // credit-card-number-regex-start
    // This regex comes from: https://www.regular-expressions.info/creditcard.html
    var creditCardNumberRegex =
      /.*(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11}).*/gim
    var creditCardNumberReplacement = "[CREDIT CARD NUMBER REDACTED]"
    // credit-card-number-regex-end

    // email-regex-start
    // This regex comes from: https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression#answer-201378
    var emailRegex =
      /.*(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]).*/gim
    var emailReplacement = "[EMAIL REDACTED]"
    // email-regex-end

    // phone-number-regex-start
    // This regex comes from: https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number#answer-16699507
    var phoneNumberRegex =
      /.*(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}.*/gim
    var phoneNumberReplacement = "[PHONE NUMBER REDACTED]"
    // phone-number-regex-end

    // address-regex-start
    // This regex was created by looking at keywords we display in our front-end and from: https://docs.datadoghq.com/agent/faq/commonly-used-log-processing-rules/#postal-codes
    var addressRegex =
      /.*(address|country|state|street|zip|postal code|postal-code)|(?:\d{5}-\d{4}|\d{5}|[A-Z]\d[A-Z] \d[A-Z]\d).*/gim
    var addressReplacement = "[ADDRESS REDACTED]"
    // address-regex-end

    // personal-name-regex-start
    // This regex was determined by what might proceed or tail an actual name in a string.
    var personalNameRegex =
      /.*(first name|firstname|first_name|first-name|last name|lastname|last_name|last-name).*/gim
    var personalNameReplacement = "[PERSONAL NAME REDACTED]"
    // personal-name-regex-end

    // social-security-regex-start
    // This regex comes from: https://docs.datadoghq.com/agent/faq/commonly-used-log-processing-rules/#social-security-numbers
    var socialSecurityNumberRegex = /.*(?:\d{3}-?\d{2}-?\d{4}).*/gim
    var socialSecurityNumberReplacement = "[SOCIAL SECURITY NUMBER REDACTED]"
    // social-security-regex-end

    function clean(dirty) {
      return dirty
        .replace(bankingInfoRegex, bankingInfoReplacement)
        .replace(creditCardNumberRegex, creditCardNumberReplacement)
        .replace(emailRegex, emailReplacement)
        .replace(phoneNumberRegex, phoneNumberReplacement)
        .replace(addressRegex, addressReplacement)
        .replace(personalNameRegex, personalNameReplacement)
        .replace(socialSecurityNumberRegex, socialSecurityNumberReplacement)
    }

    datadogRum.init({
      // https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
      applicationId: DATADOG_RUM_APPLICATION_ID,
      clientToken: DATADOG_RUM_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: "team_edition_frontend",
      env: process.env.NODE_ENV,
      //  version: '1.0.0',
      sampleRate: 100,
      trackInteractions: true, // https://docs.datadoghq.com/real_user_monitoring/browser/tracking_user_actions/?tab=cdnsync
      allowedTracingOrigins: [/https:\/\/.*\.ncsasports\.org/], // https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces?tab=browserrum
      beforeSend: function (event) {
        // https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=cdnsync#modify-the-content-of-a-rum-event

        // Resource Sanitization
        if (event.resource) {
          event.resource.url = clean(event.resource.url)
        }

        // View Sanitization
        if (event.view) {
          event.view.url = clean(event.view.url)

          if (event.view.referrer) {
            event.view.referrer = clean(event.view.referrer)
          }
        }

        // Action Sanitization
        if (event.action) {
          if (event.action.target) {
            event.action.target.name = clean(event.action.target.name)
          }
        }

        // Error Sanitization
        if (event.error) {
          event.error.message = clean(event.error.message)

          if (event.error.resource) {
            event.error.resource.url = clean(event.error.resource.url)
          }

          if (event.error.stack) {
            event.error.stack = clean(event.error.stack)
          }
        }
      },
    })
  }
}

export { setupDataDogRUM }
