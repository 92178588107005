import PropTypes from "prop-types"
import React from "react"

import moment from "utils/immutable-moment"

const assemble = (college) => {
  return {
    collegeId: String(college["college-id"]),
    collegeName: college["college-name"],

    collegeCity: college["college-city"],
    collegeState: college["college-state"],
    divisionName: college["division-name"],
    hasCollegeActivity: college["college-activity"],
    lastActivityDate: moment(college["last-activity-date"]),
    logoUrl: college["logo-url"],

    athleteReceivedEmailsCount:
      college["activity-summary"]["athlete-received-emails-count"],
    athleteSentEmailsCount:
      college["activity-summary"]["athlete-sent-emails-count"],
    coachFollowsCount: college["activity-summary"]["follows-count"],
    profileViewsCount: college["activity-summary"]["profile-views-count"],
    ncsaMatchPercentage: college["match-percentage"],
    isNcsaMatch: college["ncsa-match"],

    athleteInterested: college["athlete-interested"],
    coachRecommended:
      college["team-coach-recommended"] === "true" ||
      college["team-coach-recommended"] === true,
  }
}

const schema = PropTypes.shape({
  collegeId: PropTypes.string.isRequired,

  collegeName: PropTypes.string.isRequired,
  collegeCity: PropTypes.string.isRequired,
  collegeState: PropTypes.string.isRequired,
  divisionName: PropTypes.string.isRequired,
  hasCollegeActivity: PropTypes.bool.isRequired,
  lastActivityDate: PropTypes.object,
  logoUrl: PropTypes.string.isRequired,

  athleteReceivedEmailsCount: PropTypes.number.isRequired,
  athleteSentEmailsCount: PropTypes.number.isRequired,
  coachFollowsCount: PropTypes.number.isRequired,
  profileViewsCount: PropTypes.number.isRequired,

  athleteInterested: PropTypes.bool.isRequired,
  coachRecommended: PropTypes.bool.isRequired,
})

export default {
  assemble,
  schema,
}
