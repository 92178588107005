import PropTypes from "prop-types"
import React from "react"

import CardPhoto from "utils/cards/CardPhoto"
import CardText from "utils/cards/CardText"
import College from "colleges/college"
import CollegePresenter from "colleges/CollegePresenter"
import Icon from "utils/Icon"
import RequestRecommendCollege from "athletes/request-recommend-college"
import Track from "utils/tracking/track"
import E from "utils/tracking/event-parameters"

export default class CollegeRecommendationCard extends React.Component {
  constructor(props) {
    super(props)

    this.handleRecommendation = this.handleRecommendation.bind(this)
    this.toggleReccommended = this.toggleReccommended.bind(this)

    this.state = {
      college: this.props.college,
    }
  }

  handleRecommendation() {
    const params = [
      this.props.athleteId,
      this.state.college.id,
      { onSuccess: this.toggleReccommended },
    ]

    if (this.state.college.isRecommended) {
      this.trackRecommendation(E.ACTION.UNDO_RECOMMEND)
      return RequestRecommendCollege.undo(...params)
    } else {
      this.trackRecommendation(E.ACTION.RECOMMEND)
      return RequestRecommendCollege.execute(...params)
    }
  }

  trackRecommendation(action) {
    Track.event({
      action,
      category: E.CATEGORY.AP_SEARCH,
      label: JSON.stringify({
        athleteId: this.props.athleteId,
        recommendedCollegeId: this.state.college.id,
      }),
    })
  }

  toggleReccommended() {
    this.setState((prevState) => {
      const updatedCollege = prevState.college
      updatedCollege.isRecommended = !updatedCollege.isRecommended
      return { college: updatedCollege }
    })
    this.props.onRecommendation(
      this.state.college,
      this.state.college.isRecommended
    )
  }

  render() {
    const presenter = new CollegePresenter(this.state.college)

    return (
      <li className="card card-extended">
        <div className="card-content">
          <div className="card-profile">
            <CardPhoto
              altImageText={presenter.logoAltText()}
              photoUrl={presenter.logoUrl()}
            />

            <CardText
              title={presenter.name()}
              subtitle={presenter.profileDetails()}
            />
          </div>

          <table className="card-table">
            <tbody>
              <tr>
                <td
                  onClick={this.handleRecommendation}
                  style={{ color: "#000" }}
                >
                  <Icon {...presenter.recommendIconProps()} />
                </td>
              </tr>

              <tr>
                <th>{presenter.recommendText()}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </li>
    )
  }
}

CollegeRecommendationCard.propTypes = {
  athleteId: PropTypes.string.isRequired,
  college: College.schema.isRequired,
  onRecommendation: PropTypes.func.isRequired,
}
