import _ from "lodash"

const routes = {
  administration: () => "/administration",
  // TODO: Make this take a `team_id` or something to account for the team dropdown
  // Possibly also something like `/activity/
  activity: () => "/activity",
  athlete: (athleteId) => `/athletes/${athleteId}`,
  college: (collegeId) => `/colleges/${collegeId}`,
  organization: (organizationId) => `/organizations/${organizationId}`,
  findColleges: () => "/colleges",
  forgotPassword: () => "/forgot_password",
  pageNotAuthorized: () => "/not_authorized",
  partnerAdmin: (partnerAdminId) => `/partner_admins/${partnerAdminId}`,
  resetPassword: () => "/reset_password",
  root: () => "/",
  signIn: () => "/sign_in",
  sportsEngineSignIn: () => "/sports_engine",
  rosterManagment: () => "/roster_management",
  public: (sport, state, organizationName) =>
    `/teams/${sport}/${state}/${organizationName}`,
  athleteSignUp: (orgSlug) => `/teams/${orgSlug}/sign_up`,
  userAccount: () => "/user_account",
  termsOfService: () => "/terms_of_service",
  paymentAccount: () => "/create_payment_account",
  isActive: (pathname, route) => _.includes(pathname, route),
}

export default routes
