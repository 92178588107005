import Athlete from "athletes/athlete"
import AthleteTeam from "athletes/athlete-team"
import Coach from "coaches/coach"
import College from "colleges/college"
import Contract from "organization-contracts/organization-contract"
import EvaluationDescriptions from "athletes/evaluation-descriptions"
import HighSchool from "organization/high-school"
import Organization from "organization/organization"
import OrganizationAccount from "organization-accounts/organization-account"
import OrganizationNotes from "organization/organization-notes"
import OrganizationSport from "teams/organization-sport"
import PartnerAdmin from "partner-admins/partner-admin"
import Partner from "partner/partner"
import Payment from "organization-contracts/payment"
import NcsaSport from "organizations/ncsa-sport"
import Sport from "organization-contracts/sport"
import PricingTier from "organization-contracts/pricing-tier"
import Team from "teams/team"

class UnrecognizedResourceType extends Error {}

const assemble = (resourceType, data) => {
  switch (resourceType) {
    case Athlete.RESOURCE_TYPE:
      return Athlete.assemble(data)
    case AthleteTeam.RESOURCE_TYPE:
      return AthleteTeam.assemble(data)
    case Coach.RESOURCE_TYPE:
      return Coach.assemble(data)
    case College.RESOURCE_TYPE:
      return College.assemble(data)
    case Contract.RESOURCE_TYPE:
      return Contract.assemble(data)
    case EvaluationDescriptions.RESOURCE_TYPE:
      return EvaluationDescriptions.assemble(data)
    case HighSchool.RESOURCE_TYPE:
      return HighSchool.assemble(data)
    case Organization.RESOURCE_TYPE:
      return Organization.assemble(data)
    case OrganizationAccount.RESOURCE_TYPE:
      return OrganizationAccount.assemble(data)
    case OrganizationNotes.RESOURCE_TYPE:
      return OrganizationNotes.assemble(data)
    case OrganizationSport.RESOURCE_TYPE:
      return OrganizationSport.assemble(data)
    case NcsaSport.RESOURCE_TYPE:
      return NcsaSport.assemble(data)
    case Partner.RESOURCE_TYPE:
      return Partner.assemble(data)
    case PartnerAdmin.RESOURCE_TYPE:
      return PartnerAdmin.assemble(data)
    case Payment.RESOURCE_TYPE:
      return Payment.assemble(data)
    case PricingTier.RESOURCE_TYPE:
      return PricingTier.assemble(data)
    case Sport.RESOURCE_TYPE:
      return Sport.assemble(data)
    case Team.RESOURCE_TYPE:
      return Team.assemble(data)
    default:
      throw new UnrecognizedResourceType(
        `No assembler defined for ${resourceType}`
      )
  }
}

const assembleResource = (data) => {
  if (Array.isArray(data)) {
    return data.map((element) => assemble(element.type, element))
  } else {
    return assemble(data.type, data)
  }
}

export { assembleResource }
