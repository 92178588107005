import moment from "moment"
import { exists } from "utils/js-helpers"

export default class CollegeActivitiesPresenter {
  constructor(college) {
    this.SEPARATOR = "  •  "

    this.college = college
  }

  name() {
    return this.college.name
  }

  logoUrl() {
    return this.college.logoUrl
  }

  altImageText() {
    return `${this.college.name} logo`
  }

  location() {
    return `${this.college.city}, ${this.college.state}`
  }

  activitySubtitle() {
    const entries = [this.college.division, this.location(), this.enrollment()]

    return entries.filter(exists).join(this.SEPARATOR)
  }

  enrollment() {
    const enrollment = this.college.enrollment

    if (exists(enrollment)) {
      return `Enrollment: ${this.formatNumber(enrollment)}`
    } else {
      return ""
    }
  }

  formatNumber(number) {
    return number.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }

  activityMetrics() {
    return [
      {
        label: "Last Activity",
        value: this.college.activitySummary.lastActivityDate
          ? moment(this.college.activitySummary.lastActivityDate).format(
              "YYYY/MM/DD"
            )
          : null,
      },
      {
        label: "Views",
        value: this.college.activitySummary.viewsCount,
      },
      {
        label: "Follows",
        value: this.college.activitySummary.followsCount,
      },
      {
        label: "Sent",
        value: this.college.activitySummary.sentEmails,
        labelIcon: "envelope",
        labelIconSet: "far",
      },
      {
        label: "Received",
        value: this.college.activitySummary.receivedEmails,
        labelIcon: "envelope",
        labelIconSet: "far",
      },
    ]
  }
}
