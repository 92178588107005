import PropTypes from "prop-types"
import React from "react"

import moment from "utils/immutable-moment"

const assemble = (measurable) => {
  return {
    key: measurable["key"],
    value: measurable["value"],
    verification: measurable["verification"],
    measurmentDate: measurable["measurment-date"]
      ? moment(measurable["measurment-date"])
      : {},
  }
}

const schema = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.string,
  verification: PropTypes.string,
  measurmentDate: PropTypes.object,
})

export default {
  assemble,
  schema,
}
