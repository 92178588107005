import _ from "lodash"
import React from "react"
import PropTypes from "prop-types"
import { unique } from "utils/js-helpers"

const SEPARATOR = ";"

const toQueryString = (options = {}) => {
  return Object.keys(options)
    .map((key) => `${key}=${options[key]}`)
    .join("&")
}

function joinEmails(emails) {
  return _.isArray(emails)
    ? unique(emails.filter(Boolean)).join(SEPARATOR)
    : emails
}

export function generateMailtoLink(email, headers) {
  let mailtoLink = `mailto:${joinEmails(email)}`
  if (headers) {
    const cleanHeaders = { ...headers }
    if (headers.cc) cleanHeaders.cc = joinEmails(headers.cc)
    if (headers.bcc) cleanHeaders.bcc = joinEmails(headers.bcc)
    mailtoLink += `?${toQueryString(cleanHeaders)}`
  }
  return mailtoLink
}

function Mailto(props) {
  const href = generateMailtoLink(props.email, props.headers)
  return (
    <a href={href} target="_blank" {...props}>
      {props.children}
    </a>
  )
}

export function mailtoWithProps(generateProps) {
  return (props) => (
    <Mailto {...generateProps(props)} {...props}>
      {props.children}
    </Mailto>
  )
}

const EmailsPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string),
])

Mailto.propTypes = {
  email: EmailsPropType,
  headers: PropTypes.shape({
    cc: EmailsPropType,
    bcc: EmailsPropType,
    subject: PropTypes.string,
    body: PropTypes.string,
  }),
}

export default Mailto
