import PropTypes from "prop-types"
import React from "react"
import moment from "utils/immutable-moment"

const RESOURCE_TYPE = "payments"

const assemble = (data) => {
  return {
    id: data.id,
    paymentDate: moment(data.attributes["payment-date"]),
    amount: parseFloat(data.attributes["amount"] || 0),
    status: data.attributes["status"],
    log: data.attributes.log,
  }
}

const schema = PropTypes.shape({
  id: PropTypes.string.isRequired,
  paymentDate: PropTypes.object.isRequired,
  amount: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  log: PropTypes.arrayOf(PropTypes.object).isRequired,
})

export default {
  RESOURCE_TYPE,
  assemble,
  schema,
}
