import {
  REQUEST_ORGANIZATION,
  RECEIVE_ORGANIZATION,
  INCREMENT_ORGANIZATION_TEAM_NUMBER,
  DECREMENT_ORGANIZATION_TEAM_NUMBER,
  RESET_ORGANIZATION,
} from "store/actions/organization"

const INITIAL_STATE = {
  isFetching: false,
  data: {},
}

const organization = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_ORGANIZATION:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_ORGANIZATION:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.organization,
      })
    case INCREMENT_ORGANIZATION_TEAM_NUMBER: {
      const { data } = state

      const updatedOrganization = {
        ...data,
        numberOfTeams: data.numberOfTeams + 1,
      }

      return {
        ...state,
        data: updatedOrganization,
      }
    }
    case DECREMENT_ORGANIZATION_TEAM_NUMBER: {
      const { data } = state

      const updatedOrganization = {
        ...data,
        numberOfTeams: data.numberOfTeams - 1,
      }

      return {
        ...state,
        data: updatedOrganization,
      }
    }
    case RESET_ORGANIZATION: {
      return INITIAL_STATE
    }
    default:
      return state
  }
}

export default organization
