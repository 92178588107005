import PropTypes from "prop-types"
import React from "react"

const assemble = (activitySummary) => {
  return {
    viewsCount: activitySummary["views-count"],
    followsCount: activitySummary["follows-count"],
    sentEmails: activitySummary["sent-emails"],
    receivedEmails: activitySummary["received-emails"],
    lastActivityDate: activitySummary["last-activity-date"],
  }
}

const schema = PropTypes.shape({
  viewsCount: PropTypes.number,
  followsCount: PropTypes.number,
  sentEmails: PropTypes.number,
  receivedEmails: PropTypes.number,
  lastActivityDate: PropTypes.string,
})

export default {
  assemble,
  schema,
}
