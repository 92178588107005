import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import moment from "moment"
import Alert from "utils/Alert"
import College from "colleges/college"
import CollegeActivitiesCard from "colleges/CollegeActivitiesCard"
import Paginator from "utils/search/Paginator"
import Sort from "utils/Sort"
import SortUtils from "utils/sort-utils"
import E from "utils/tracking/event-parameters"
import Track from "utils/tracking/track"

const SORT_ACTIONS = {
  viewsCount: E.ACTION.SORT_BY_VIEWS,
  followsCount: E.ACTION.SORT_BY_FOLLOWS,
  sentEmails: E.ACTION.SORT_BY_SENT,
  receivedEmails: E.ACTION.SORT_BY_RECEIVED,
}

export const DEFAULT_MESSAGE = "Most Recent Activity"
export const SEARCH_RESULT_MESSAGE = "Search Results"
export default class CollegeList extends React.Component {
  constructor(props) {
    super(props)

    this.PER_PAGE = 25

    this.handleSortBy = this.handleSortBy.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.createLinksObject = this.createLinksObject.bind(this)

    this.state = {
      pageNum: 1,
      links: this.props.responsePayload.links,
      ascendingOrder: true,
      sortField: "lastActivityDate",
    }
  }

  get sortOptions() {
    return [
      {
        label: "Name",
        field: "name",
        orderBy: (college) => college.name,
      },
      {
        label: "Last Activity",
        field: "lastActivityDate",
        orderBy: (college) =>
          college.activitySummary.lastActivityDate
            ? -moment(college.activitySummary.lastActivityDate).unix()
            : 0,
      },
      {
        label: "Views",
        field: "viewsCount",
        orderBy: (college) => college.activitySummary.viewsCount,
      },
      {
        label: "Follows",
        field: "followsCount",
        orderBy: (college) => college.activitySummary.followsCount,
      },
      {
        label: "Sent",
        field: "sentEmails",
        orderBy: (college) => college.activitySummary.sentEmails,
      },
      {
        label: "Received",
        field: "receivedEmails",
        orderBy: (college) => college.activitySummary.receivedEmails,
      },
    ]
  }

  componentDidMount() {
    const { colleges, onOpenFilters } = this.props

    if (colleges.length === 0) {
      onOpenFilters()
    }
  }

  handleSortBy(fieldName) {
    this.setState(
      (prevState) => ({
        ascendingOrder: this.isAscendingSortOrder(prevState, fieldName),
        sortField: fieldName,
      }),
      this.onPageChange(1)
    )
    Track.event({
      action: SORT_ACTIONS[fieldName],
      category: E.CATEGORY.FIND_COLLEGE,
    })
  }

  isAscendingSortOrder(prevState, fieldName) {
    return prevState.sortField === fieldName ? !prevState.ascendingOrder : false
  }

  sortedColleges() {
    if (this.state.sortField) {
      const getValue =
        _.find(this.sortOptions, { field: this.state.sortField }).orderBy ||
        (() => null)
      return SortUtils.sortBy(
        this.props.colleges,
        getValue,
        this.getSortComparator()
      )
    }

    return this.props.colleges
  }

  getSortComparator() {
    return this.state.ascendingOrder
      ? SortUtils.ascendingComparator
      : SortUtils.descendingComparator
  }

  createLinksObject(pageNum) {
    const baseLinksObj = this.props.responsePayload.links
    const baseLink = baseLinksObj.self.slice(0, -1)
    const lastPage = baseLinksObj.last[baseLinksObj.last.length - 1]
    const nextLink =
      parseInt(lastPage) === pageNum ? null : baseLink + (pageNum + 1)
    const prevLink = pageNum > 1 ? baseLink + (pageNum - 1) : null

    return {
      first: baseLink + 1,
      last: baseLink + lastPage,
      next: nextLink,
      prev: prevLink,
      self: baseLink + pageNum,
    }
  }

  onPageChange(pageNum) {
    this.setState({
      pageNum: pageNum,
      links: this.createLinksObject(pageNum),
    })
  }

  selectedColleges() {
    const start = this.PER_PAGE * (this.state.pageNum - 1)
    const finish = this.PER_PAGE * this.state.pageNum

    return this.sortedColleges().slice(start, finish)
  }

  render() {
    return (
      <div className="college-filters-result">
        {this.props.colleges.length ? (
          <div>
            <div className="college-search">
              <h2>{this.props.filtersApplied ? SEARCH_RESULT_MESSAGE : ""}</h2>
              <Sort
                ascendingOrder={this.state.ascendingOrder}
                currentSortField={this.state.sortField}
                handleSortBy={this.handleSortBy}
                sortOptions={this.sortOptions}
              />
            </div>

            <ul className="cards">
              {this.selectedColleges().map((college) => (
                <CollegeActivitiesCard key={college.id} college={college} />
              ))}
            </ul>

            <Paginator
              links={this.state.links}
              onPageChange={this.onPageChange}
            />
          </div>
        ) : (
          <Alert type="warning">
            There are no colleges matching your search.
          </Alert>
        )}
      </div>
    )
  }
}

CollegeList.propTypes = {
  colleges: PropTypes.arrayOf(College.schema).isRequired,
  query: PropTypes.string,
  filters: PropTypes.object,
  responsePayload: PropTypes.object.isRequired,
  onOpenFilters: PropTypes.func.isRequired,
  filtersApplied: PropTypes.bool,
}
