import PropTypes from "prop-types"
import React from "react"

import moment from "utils/immutable-moment"

const assemble = (eventMeasurable) => {
  return {
    key: eventMeasurable["key"],
    value: eventMeasurable["value"],
    eventName: eventMeasurable["event-name"],
    eventDate: moment(eventMeasurable["event-date"]),
    displayOnProfile: eventMeasurable["display-on-profile"],
  }
}

const schema = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  eventDate: PropTypes.object,
  displayOnProfile: PropTypes.bool,
})

export default {
  assemble,
  schema,
}
