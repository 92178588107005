import PropTypes from "prop-types"
import React from "react"

import SearchField from "utils/search/SearchField"

export default class SearchBar extends React.Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    this.state = {
      searchQuery: props.defaultValue,
    }
  }

  onChange(value) {
    this.setState({ searchQuery: value })
  }

  onSubmit(event) {
    if (event) {
      event.preventDefault()
    }
    this.props.onSearch(this.state.searchQuery)
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <SearchField
          label={this.props.label}
          placeholder={this.props.placeholder}
          onChange={this.onChange}
          value={this.state.searchQuery}
        />
      </form>
    )
  }
}

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
}

SearchBar.defaultProps = {
  defaultValue: "",
}
