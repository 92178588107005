import { Link } from "react-router"
import React from "react"

import ActivityTable from "utils/ActivityTable"
import CardPhoto from "utils/cards/CardPhoto"
import CardText from "utils/cards/CardText"
import College from "colleges/college"
import classnames from "classnames"
import CollegeActivitiesPresenter from "colleges/CollegeActivitiesPresenter"
import Drawer from "utils/Drawer"
import EmailCollegeButton from "athletes/EmailCollegeButton"
import Routes from "app/routes"
import Icon from "utils/Icon"
import E from "utils/tracking/event-parameters"
import Track from "utils/tracking/track"
import Session from "app/session"

const CollegeActivitiesCard = ({ college }) => {
  const presenter = new CollegeActivitiesPresenter(college)

  const publicTagClass = classnames("tag public", {
    hidden: !college.privateOrPublic,
  })

  const typeTagClass = classnames("tag type", {
    hidden: !college.type || college.type === "NONE",
  })

  const publicTag = classnames({
    Public: college.privateOrPublic === "PUBLIC",
    Private: college.privateOrPublic === "PRIVATE",
  })

  const typeTag = classnames({
    "Men's": college.type === "MEN",
    "Women's": college.type === "WOMEN",
    Military: college.type === "MILITARY",
    HBC: college.type === "HBC",
    Christian: college.type === "CHRISTIAN",
  })

  const handleCollegeProfile = () => {
    Track.event({
      action: E.ACTION.VIEW_COLLEGE_PROFILE,
      category: E.CATEGORY.FIND_COLLEGE,
    })
  }

  return (
    <li
      key={college.id}
      className="card card-college card-extended card-drawer"
    >
      <Link onClick={handleCollegeProfile} to={Routes.college(college.id)}>
        <div className="card-content">
          <div className="card-profile">
            <CardPhoto
              photoUrl={presenter.logoUrl()}
              altImageText={presenter.altImageText()}
            />

            <CardText
              title={presenter.name()}
              subtitle={presenter.activitySubtitle()}
            >
              <div className="tag-group">
                <span className={publicTagClass}>
                  <Icon type="university" /> {publicTag}
                </span>

                <span className={typeTagClass}>
                  <Icon type="graduation-cap" /> {typeTag}
                </span>
              </div>
            </CardText>
          </div>

          <ActivityTable metrics={presenter.activityMetrics()} />
        </div>
      </Link>
      {(Session.isPartnerAdmin() || Session.isVerifiedCoach()) && (
        <Drawer>
          <EmailCollegeButton
            college={college}
            collegeId={college.id}
            trackingCategory={E.CATEGORY.FIND_COLLEGE}
          />
        </Drawer>
      )}
    </li>
  )
}

CollegeActivitiesCard.propTypes = {
  college: College.schema.isRequired,
}

export default CollegeActivitiesCard
