import _ from "lodash"
import uaParser from "ua-parser-js"

export const AGENT_TYPE = {
  DESKTOP: "web_desktop",
  MOBILE: "web_mobile",
  TABLET: "web_tablet",
}

export function determineUserAgent() {
  const ua = uaParser(navigator.userAgent)
  switch (_.get(ua, "device.type")) {
    case "mobile":
      return AGENT_TYPE.MOBILE
    case "tablet":
      return AGENT_TYPE.TABLET
    default:
      return AGENT_TYPE.DESKTOP
  }
}
