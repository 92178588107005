import _ from "lodash"
import Session from "app/session"
import { localStorage } from "utils/storageHelper"

export const SPORT_SWITCHER_KEY = "SPORT_SWITCHER_KEY"

function storageKey() {
  return `${SPORT_SWITCHER_KEY}-${Session.organizationId()}`
}

export function getSelectedSport() {
  const formData = localStorage.getItem(storageKey())
  if (!formData) return null
  return _.get(formData, "sport", "")
}

export function setSelectedSport(sport) {
  localStorage.setItem(storageKey(), { sport, updatedAt: new Date() })
}

export function shouldShowSportSwitcher(currentOrganization) {
  if (_.get(currentOrganization, "sports", []).length <= 1) return false
  // TODO: This is a pretty janky way of checking that we're on the dashboard page.
  // Maybe there's something in react-router that can help?
  if (_.last(window.location.href.split("/")) !== "") return false
  return true
}
