import PropTypes from "prop-types"
import React from "react"
import { withRouter } from "react-router"

import { assembleResource } from "utils/providers/assemble-resource"
import { collegeEndpoint } from "utils/endpoints"
import Athlete from "athletes/athlete"
import E from "utils/tracking/event-parameters"
import { trackButtonClick, BUTTON_CLICKS } from "utils/coachEventTracking"
import Icon from "utils/Icon"
import allCollegeCoachesEmailTemplate from "mailto-templates/all-college-coaches"
import allCollegeCoachesForAthleteEmailTemplate from "mailto-templates/all-college-coaches-for-athlete"
import Request from "utils/Request"
import Track from "utils/tracking/track"

export const EmailCollegeButton = ({
  athlete,
  collegeId,
  trackingCategory,
  location,
}) => {
  const trackEmailCoachesEvent = (college) => {
    Track.event({
      action: E.ACTION.EMAIL_COACHES,
      category: trackingCategory,
      label: JSON.stringify({
        coachNames: college.coaches.map((coach) => coach.name),
        collegeId,
      }),
    })
  }

  const getCollege = (onSuccess) => {
    return Request.get({
      endpoint: collegeEndpoint(collegeId),
      onSuccess: (result) => onSuccess(assembleResource(result.json.data)),
    })
  }

  const openMailToLink = (college) => {
    const link = athlete
      ? allCollegeCoachesForAthleteEmailTemplate(athlete, college)
      : allCollegeCoachesEmailTemplate(college)
    window.open(link, "_blank")
  }

  const emailCollegeCoaches = () => {
    getCollege((college) => {
      trackEmailCoachesEvent(college)
      trackButtonClick(BUTTON_CLICKS.EMAIL_COACHES, location.pathname)
      openMailToLink(college)
    })
  }

  return (
    <li className="drawer-item">
      <a href="javascript:void(0)" onClick={emailCollegeCoaches}>
        <Icon type="envelope" set="far" />
        <br />
        Contact Coaches
      </a>
    </li>
  )
}

EmailCollegeButton.propTypes = {
  athlete: Athlete.schema,
  collegeId: PropTypes.string.isRequired,
  trackingCategory: PropTypes.string.isRequired,
}

export default withRouter(EmailCollegeButton)
