import PropTypes from "prop-types"
import React from "react"
import moment from "utils/immutable-moment"

const assemble = (weeklyData) => {
  const weeksAgo = (n) => moment().startOf("isoWeek").subtract({ weeks: n })

  return {
    startDate: weeksAgo(weeklyData["weeks-ago"]),
    profileViewsCount: weeklyData["view-count"],
    coachFollowsCount: weeklyData["follow-count"],
    receivedEmailsCount: weeklyData["message-received-count"],
  }
}

const schema = PropTypes.shape({
  startDate: PropTypes.string.isRequired,
  profileViewsCount: PropTypes.string.isRequired,
  coachFollowsCount: PropTypes.string.isRequired,
  receivedEmailsCount: PropTypes.string.isRequired,
})

export default {
  assemble,
  schema,
}
