import * as types from "store/actions/organizations"
import { getFilterKey } from "store/reducers/organizations"

export default (state = {}, action) => {
  const { type } = action

  switch (type) {
    case types.RECEIVE_ORGANIZATIONS: {
      const { filters, page, organizations, totalPages } = action

      const key = getFilterKey(filters)

      const pages = state[key] && state[key]["pages"] ? state[key]["pages"] : {}

      return {
        ...state,
        ...{
          [key]: {
            totalPages,
            pages: {
              ...pages,
              [page]: organizations.map((org) => org.id),
            },
          },
        },
      }
    }
    default:
      return state
  }
}
