export default class StorageHelper {
  constructor(storage) {
    this.storage = storage
  }

  setItem(key, value) {
    this.storage.setItem(key, JSON.stringify(value))
  }

  getItem(key) {
    return JSON.parse(this.storage.getItem(key) || null)
  }

  hasItem(key) {
    return !_.isNull(this.getItem(key))
  }

  removeItem(key) {
    this.storage.removeItem(key)
  }
}

export const localStorage = new StorageHelper(window.localStorage)
export const sessionStorage = new StorageHelper(window.sessionStorage)
