const TRACKING = {
  ACTION: {
    DEFAULT: "unspecified",

    EMAIL_ATHLETE: "email-athlete",
    EMAIL_ATHLETES: "email-all-shown-athletes",

    EMAIL_COACH: "email-college-coach",
    EMAIL_COACHES: "email-college-all-coaches",

    FILTER_TRANSCRIPT_ADDED: "dashboard-filter-transcript-present",
    FILTER_TRANSCRIPT_MISSING: "dashboard-filter-transcript-missing",

    FILTER_GPA_ADDED: "dashboard-filter-gpa-present",
    FILTER_GPA_MISSING: "dashboard-filter-gpa-missing",

    FILTER_SAT_ADDED: "dashboard-filter-sat-present",
    FILTER_SAT_MISSING: "dashboard-filter-sat-missing",

    FILTER_VIDEO_WEEK: "dashboard-filter-video-uploaded-past-week",
    FILTER_VIDEO_MONTH: "dashboard-filter-video-uploaded-week-to-month",
    FILTER_VIDEO_OLD: "dashboard-filter-video-uploaded-month-or-longer",
    FILTER_VIDEO_NEVER: "dashboard-filter-video-uploaded-never",

    FILTER_SENT_WEEK: "dashboard-filter-email-sent-past-week",
    FILTER_SENT_MONTH: "dashboard-filter-email-sent-week-to-month",
    FILTER_SENT_OLD: "dashboard-filter-email-sent-month-or-longer",
    FILTER_SENT_NEVER: "dashboard-filter-email-sent-never",

    RECOMMEND: "recommend-college",
    UNDO_RECOMMEND: "undo-recommend-college",

    SUBMIT_EVALUATION: "submit-athlete-evaluation",
    CANCEL_EVALUATION: "cancel-athlete-evaluation",

    VIEW_ADMIN_ATHLETES: "view-organization-athletes",
    VIEW_ADMIN_ORGANIZATION: "view-organization-details",
    VIEW_ADMIN_STAFF: "view-organization-staff",
    VIEW_ADMIN_TEAMS: "view-organization-teams",

    VIEW_AP_COLLEGE_ACTIVITY: "view-athlete-profile-college-activity",
    VIEW_AP_TARGET_COLLEGES: "view-athlete-profile-target-colleges",
    VIEW_AP_PREFERENCES: "view-athlete-profile-preferences",
    VIEW_AP_EVALUATION: "view-athlete-profile-evaluation",
    VIEW_AP_STATISTICS: "view-athlete-profile-statistics",

    VIEW_ATHLETE_ACTIVITY: "view-dashboard-athlete-activity",
    VIEW_COLLEGE_COACH_ACTIVITY: "view-dashboard-college-coach-activity",

    VIEW_TEAM_ATHLETES: "view-team-directory-athletes",
    VIEW_TEAM_STAFF: "view-team-directory-staff",

    VIEW_ATHLETE_PROFILE: "view-athlete-profile",
    VIEW_VIDEO_UPLOADED: "view-video-uploaded",
    VIEW_KEY_STATS: "view-key-stats",

    VIEW_COLLEGE_PROFILE: "view-college-profile",
    VIEW_COLLEGE_WEBSITE: "view-college-website",

    CAPTURE_ROSTER_LEAD: "capture-roster-lead",
    SELECT_ATHLETE_PROFILE: "select-athlete-profile",

    SORT_BY_ATHLETE_NAME: "sort-by-athlete-name",
    SORT_BY_GRAD_YEAR: "sort-by-grad-year",
    SORT_BY_POSITION: "sort-by-position",
    SORT_BY_TEAM_NAME: "sort-by-team-name",
    SORT_BY_VIDEO: "sort-by-video",
    SORT_BY_GPA: "sort-by-gpa",
    SORT_BY_KEY_STATS: "sort-by-key-stats",
    SORT_BY_TRANSCRIPT: "sort-by-transcript",
    SORT_BY_COMMITTED: "sort-by-committed",

    SORT_BY_VIEWS: "sort-by-views",
    SORT_BY_FOLLOWS: "sort-by-follows",
    SORT_BY_SENT: "sort-by-sent",
    SORT_BY_RECEIVED: "sort-by-received",

    ClICK_RECRUITING_HELP: "recruiting-help",

    CLICK_COPY_LINK: "click-copy-link",
    CLICK_ROSTER_PAGE: "click-roster-page",

    FILTER_ROSTER_TEAM: "filter-roster-team",
    FILTER_SPORT_TEAM: "filter-sport-team",

    SEARCH_COLLEGE: "search-college",

    SE_SIGN_IN_SUCCESS: "sports-engine-successful-sign-in",
    SE_SIGN_IN_FAIL: "sports-engine-failed-sign-in",
  },
  CATEGORY: {
    DEFAULT: "unspecified",

    ADMINISTRATION: "administration",

    NAVIGATION: "navigation",

    AP_COLLEGE: "athlete-profile-college",
    AP_EVALUATION: "athlete-profile-evalution",
    AP_HEADER: "athlete-profile-main-header",
    AP_SEARCH: "athlete-profile-recommend-college-search",

    CP_HEADER: "college-profile-main-header",
    CP_ATHLETE: "college-profile-athlete",
    CP_PROFILE: "college-profile",

    DASH_ATHLETE: "dashboard-athlete",
    DASH_CONTROLS: "dashboard-controls",

    ROSTER_HEADER: "roster-header",
    ROSTER: "roster",

    TEAM_DIRECTORY: "team-directory",
    FIND_COLLEGE: "find-college",
    COLLEGE_SHOW_PAGE: "college-show-page",

    SPORTS_ENGINE: "sports-engine",
  },
}

export default TRACKING
