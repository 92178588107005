import { exists } from "utils/js-helpers"

export default class CollegePresenter {
  constructor(college) {
    this.SEPARATOR = "  •  "

    this.college = college
  }

  name() {
    return this.college.name
  }

  academicSelectivity() {
    const academicSelectivity = {
      1: "Most selective",
      2: "Selective",
      3: "Somewhat selective",
      4: "Less selective",
    }

    return academicSelectivity[this.college.academicStrength] || ""
  }

  enrollment() {
    const enrollment = this.college.enrollment

    return exists(enrollment) ? this.formatNumber(this.college.enrollment) : ""
  }

  locationType() {
    return this.college.locationType || ""
  }

  logoUrl() {
    return this.college.logoUrl
  }

  logoAltText() {
    return `${this.college.name} logo`
  }

  profileDetails() {
    return `${this.college.division}${this.SEPARATOR}${this.college.city}, ${this.college.state}`
  }

  tuitionInState() {
    return this.formatUsdCurrency(this.college.tuitionInState)
  }

  tuitionOutOfState() {
    return this.formatUsdCurrency(this.college.tuitionOutOfState)
  }

  type() {
    if (this.college.type && this.college.type !== "HIDDEN") {
      return this.college.type
    } else {
      return ""
    }
  }

  website() {
    return this.college.website
  }

  isRecommended() {
    return this.college.isRecommended
  }

  formatUsdCurrency(number) {
    return isNaN(number) ? "" : `$${this.formatNumber(number)}`
  }

  formatNumber(number) {
    return number.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }

  recommendIconProps() {
    return this.college.isRecommended
      ? { type: "thumbs-up" }
      : { type: "thumbs-up", set: "far" }
  }

  recommendText() {
    return this.college.isRecommended
      ? "Remove Recommendation"
      : "Recommend College"
  }
}
