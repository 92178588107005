import PropTypes from "prop-types"
import React from "react"

import AthleteActivity from "athletes/athlete-activity"
import CollegeCoach from "colleges/college-coach"
import CollegeActivitySummary from "colleges/college-activity-summary"

const RESOURCE_TYPE = "colleges"

const assemble = (data) => {
  return {
    id: data.id,
    name: data.attributes["name"],

    academicStrength: data.attributes["academic-strength"],
    city: data.attributes["city"],
    division: data.attributes["division"],
    enrollment: data.attributes["enrollment"],
    locationType: data.attributes["location-type"],
    logoUrl: data.attributes["logo-url"],
    privateOrPublic: data.attributes["private-or-public"],
    state: data.attributes["state"],
    tuitionInState: parseInt(data.attributes["tuition-instate"]),
    tuitionOutOfState: parseInt(data.attributes["tuition-outofstate"]),
    type: data.attributes["type"],
    website: data.attributes["website"],

    coaches: (data.attributes["coaches"] || []).map(CollegeCoach.assemble),

    activitySummary: CollegeActivitySummary.assemble(
      data.attributes["activity-summary"] || []
    ),

    athleteActivity: (data.attributes["activity"] || []).map(
      AthleteActivity.assemble
    ),
  }
}

const schema = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,

  academicStrength: PropTypes.number.isRequired,
  city: PropTypes.string.isRequired,
  division: PropTypes.string.isRequired,
  enrollment: PropTypes.number,
  locationType: PropTypes.string,
  logoUrl: PropTypes.string.isRequired,
  privateOrPublic: PropTypes.string,
  state: PropTypes.string.isRequired,
  tuitionInState: PropTypes.number.isRequired,
  tuitionOutOfState: PropTypes.number.isRequired,
  type: PropTypes.string,
  website: PropTypes.string.isRequired,

  coaches: PropTypes.arrayOf(CollegeCoach.schema),

  athleteActivity: PropTypes.arrayOf(AthleteActivity.schema),
  activitySummary: CollegeActivitySummary.schema,
})

export default {
  RESOURCE_TYPE,
  assemble,
  schema,
}
