import moment from "moment"

class ImmutableMoment {
  constructor(_moment) {
    this.moment = _moment
  }

  subtract(...args) {
    return new ImmutableMoment(this.moment.clone().subtract(...args))
  }

  add(...args) {
    return new ImmutableMoment(this.moment.clone().add(...args))
  }

  startOf(...args) {
    return new ImmutableMoment(this.moment.clone().startOf(...args))
  }

  isAfter(comparison) {
    return this.moment.isAfter(comparison.moment)
  }

  isBefore(comparison) {
    return this.moment.isBefore(comparison.moment)
  }

  format(...args) {
    return this.moment.format(...args)
  }

  isValid(...args) {
    return this.moment.isValid(...args)
  }

  clone() {
    return this
  }

  valueOf() {
    if (this.isValid(this.moment)) {
      return this.moment.valueOf()
    } else {
      return 0
    }
  }
}

const initialize = (arg) => new ImmutableMoment(moment(arg))

export default initialize
