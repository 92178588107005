import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

const MobileControlPanelButton = ({ onClick, children, inversed }) => {
  const styles = classnames(
    "m-control-panel-btn btn btn-md btn-rounded",
    { "btn-outlined--white": !inversed },
    { "btn-inversed": inversed }
  )
  return (
    <button type="button" className={styles} onClick={onClick}>
      {children}
    </button>
  )
}

MobileControlPanelButton.defaultProps = {
  inversed: false,
}

MobileControlPanelButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  inversed: PropTypes.bool,
}

export default MobileControlPanelButton
