import _ from "lodash"
import PropTypes from "prop-types"
import React from "react"

import CollegeActivity from "athletes/college-activity"
import moment from "utils/immutable-moment"
import Preferences from "athletes/preferences"
import Measurables from "athletes/measurables"
import EventMeasurables from "athletes/event-measurables"
import WeeklyActivity from "athletes/weekly-activity"

const RESOURCE_TYPE = "athletes"

const assemble = (data) => {
  let athlete = {
    id: data.id,
    teamName: data.attributes["primary-team-name"],
    teamScheduleUrl: data.attributes["primary-team-schedule"],
    sportId: String(data.attributes["sport-id"]),
    ncsaProfileUrl: data.attributes["ncsa-profile-url"],
    ncsaAccess: data.attributes["ncsa-access"],
    inviteStatus: data.attributes["invite-status"],
    zipCode: data.attributes["zip-code"],
    phone: data.attributes["phone"],
    secondaryEmail:
      data.attributes["secondary-email"] ||
      data.attributes.profile["secondary-email"],
    lastRssCheck: data.attributes["last-rss-check"],
    clientId: data.attributes["client-id"],
  }

  athlete = appendProfile(athlete, data.attributes.profile)
  athlete = appendActivity(athlete, data.attributes.activity)
  athlete = appendPreferences(athlete, data.attributes.preferences)
  athlete = appendKeyStats(athlete, data.attributes["key-stats"])

  return athlete
}

const profileCompletion = (profile) => {
  if (profile["profile-completion"]) {
    return {
      percentage: profile["profile-completion"]["percentage"] || 0,
      missingElements: profile["profile-completion"]["missing-elements"] || [],
    }
  } else {
    return {}
  }
}

const tapAssessment = (profile) => {
  return {
    athleteType: _.get(profile, "tap-assessment.athlete-type", ""),
    showOnProfile: _.get(profile, "tap-assessment.show-on-profile", false),
  }
}

const appendProfile = (athlete, profile) => {
  athlete.firstName = profile["first-name"]
  athlete.lastName = profile["last-name"]
  athlete.email = profile["email"] || ""
  athlete.ncsaProfileLink = profile["ncsa-profile-link"]
  athlete.evalDesciption = profile["team-coach-description"]
  athlete.ncsaEmail = profile["ncsa-email"] || ""
  athlete.gradYear = String(profile["grad-year"])
  athlete.height = profile["height"]
  athlete.weight = profile["weight"]
  athlete.photoUrl = profile["photo-url"]
  athlete.position = profile["position"] || ""
  athlete.starRating = profile["team-coach-rating"] || profile["ncsa-rating"]
  athlete.keyStatsCount = profile["key-stats-count"]
  athlete.keyStatsTotal = profile["key-stats-total"]
  athlete.transcript = profile["transcript"]
  athlete.gpa = profile["gpa"]
  athlete.sat = profile["sat"]
  athlete.act = profile["act"]
  athlete.collegeCommitment = profile["college-commitment"]
  athlete.videos = profile["videos"] || []
  athlete.profileCompletion = profileCompletion(profile)
  athlete.membershipType = profile["membership-type"]
  athlete.isDeactivated = profile["deactivated"]
  athlete.tapAssessment = tapAssessment(profile)
  athlete.favoritedCollegesCount = profile["favorited-colleges-count"]

  return athlete
}

const appendActivity = (athlete, activity) => {
  athlete.receivedEmailsCount = activity["athlete-received-emails-count"]
  athlete.sentEmailsCount = activity["athlete-sent-emails-count"]
  athlete.coachFollowsCount = activity["follows-count"]
  athlete.lastLoginDate = moment(activity["last-login"])
  athlete.lastCoachActivityDate = moment(activity["last-coach-activity-date"])
  athlete.profileViewsCount = activity["profile-views-count"]
  athlete.videoUpdatedDate = activity["video-updated-at"]
    ? moment(activity["video-updated-at"])
    : null
  athlete.lastSentEmailDate = moment(activity["last-email-sent-at"])
  athlete.collegeActivity = (activity["colleges"] || []).map(
    CollegeActivity.assemble
  )
  athlete.weeklyTotals = (activity["weekly-activity"] || []).map(
    WeeklyActivity.assemble
  )

  return athlete
}

const appendPreferences = (athlete, preferences) => {
  athlete.preferences = Preferences.assemble(preferences || {})

  return athlete
}

const appendKeyStats = (athlete, keyStats) => {
  const eventMeasurables = keyStats["event-measurables"]
  const sortedEventMeasurables = eventMeasurables
    ? eventMeasurables.sort(compareDates)
    : []

  athlete.measurables = (keyStats["measurables"] || []).map(
    Measurables.assemble
  )
  athlete.eventMeasurables = sortedEventMeasurables.map(
    EventMeasurables.assemble
  )

  return athlete
}

const compareDates = (a, b) => {
  if (b["event-date"] < a["event-date"]) {
    return -1
  }
  if (b["event-date"] > a["event-date"]) {
    return 1
  }
  return 0
}

const schema = PropTypes.shape({
  id: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,

  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string,
  zipCode: PropTypes.string,
  phone: PropTypes.string,
  ncsaEmail: PropTypes.string,
  gradYear: PropTypes.string.isRequired,
  ncsaProfileUrl: PropTypes.string,
  photoUrl: PropTypes.string.isRequired,
  position: PropTypes.string,
  sportId: PropTypes.string.isRequired,
  starRating: PropTypes.number,
  keyStatsCount: PropTypes.number,
  keyStatsTotal: PropTypes.number,
  transcript: PropTypes.bool,
  gpa: PropTypes.bool,
  sat: PropTypes.bool,
  act: PropTypes.bool,
  secondaryEmail: PropTypes.string,

  coachFollowsCount: PropTypes.number,
  lastCoachActivityDate: PropTypes.object,
  lastLoginDate: PropTypes.object,
  profileViewsCount: PropTypes.number,
  receivedEmailsCount: PropTypes.number,
  sentEmailsCount: PropTypes.number,
  videoUpdatedDate: PropTypes.object,

  collegeActivity: PropTypes.arrayOf(CollegeActivity.schema),
  preferences: Preferences.schema,
  measurables: PropTypes.arrayOf(Measurables.schema),
  eventMeasurables: PropTypes.arrayOf(EventMeasurables.schema),
  profileCompletion: PropTypes.shape({
    percentage: PropTypes.number,
    missingElements: PropTypes.arrayOf(PropTypes.string),
  }),
  membershipType: PropTypes.string,
})

export default {
  RESOURCE_TYPE,
  assemble,
  appendProfile,
  appendActivity,
  appendPreferences,
  appendKeyStats,
  schema,
}
