import {
  definePromiseAction,
  definePromiseActionType,
} from "store/promiseActionCreator"
import Request from "utils/Request"
import { updatesFeedEndpoint } from "utils/endpoints"

export const SET_APP_SPORT = "SET_APP_SPORT"
export const TOGGLE_COLLEGE_RECOMMENDATION = "TOGGLE_COLLEGE_RECOMMENDATION"
export const FETCH_UPDATES_FEED = definePromiseActionType("FETCH_UPDATES_FEED")

export const setAppSport = (sport) => ({
  type: SET_APP_SPORT,
  payload: sport,
})

export const toggleCollegeRecommendation = (event) => ({
  type: TOGGLE_COLLEGE_RECOMMENDATION,
  payload: event,
})

export const fetchUpdatesFeed = (organizationId) =>
  definePromiseAction({
    type: FETCH_UPDATES_FEED,
    promiseCallback: () =>
      Request.get({
        endpoint: updatesFeedEndpoint(organizationId),
      }).then((response) => response.json),
  })
