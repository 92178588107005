import * as types from "store/actions/organizations"
import { omit } from "utils/js-helpers"

export default (state = {}, action) => {
  const { type } = action
  switch (type) {
    case types.RECEIVE_ORGANIZATIONS: {
      const { organizations } = action
      return organizations.reduce((initValue, org) => {
        return {
          ...initValue,
          [org.id]: org,
        }
      }, state)
    }
    case types.ADD_ORGANIZATION: {
      const { organization } = action

      return {
        ...state,
        [organization.id]: organization,
      }
    }
    case types.DELETE_ORGANIZATION: {
      const { id } = action

      return omit(state, [id])
    }
    case types.UPDATE_ORGANIZATION: {
      const { organization } = action

      const updatedOrg = { [organization.id]: organization }

      return {
        ...state,
        ...updatedOrg,
      }
    }
    default:
      return state
  }
}
